import { backendInstance } from './base'

export const COUNTRY_URL_KEY = 'country'
const BASE_URL = '/country'

type OperatorType = {
  creationDate: string | Date
  id: number
  name: string
}

export type CountryWithOperatorType = {
  countryCode: string
  countryName: string
  operators: Array<OperatorType>
  countryOldCode: string
}

export type CountryWithOperatorResponse = CountryWithOperatorType[]

type CountryResponse = {
  id: string
  countryCode: string
  countryName: string
  flagUrl: string
  availableCount: number
  isFavorite: boolean
  cost: number | null
}

type CountryRequest = {
  favoriteIds: string[]
  serviceCode?: string
  bringTime?: string
}

export const fetchCountries = async (
  dto: CountryRequest
): Promise<CountryResponse[]> => {
  const response = await backendInstance.get(`${BASE_URL}`, {
    params: {
      favoriteIds: dto.favoriteIds,
      serviceCode: dto.serviceCode,
      bringTime: dto.bringTime
    }
  })
  return response.data
}

export const fetchCountriesWithOperators =
  async (): Promise<CountryWithOperatorResponse> => {
    const response = await backendInstance.get(`${BASE_URL}/operators`)
    return response.data
  }

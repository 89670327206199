import { ModelError } from '@shared/errors/model.error'
import { DepositRecordSchema } from '../deposit.schema'

export class DepositRecordModel {
  constructor(
    public readonly id: string,
    public readonly created: Date,
    public readonly amount: number,
    public readonly inputCurrencyCode: string | null,
    public readonly amountInputCurrency: number,
    public readonly targetCurrencyCode: string | null,
    public readonly paySystem: string
  ) {}

  static fromAPI(data: unknown): DepositRecordModel {
    const result = DepositRecordSchema.safeParse(data)
    if (!result.success) {
      throw new ModelError('INVALID_DATA_FORMAT', result.error.errors)
    }
    return new DepositRecordModel(
      result.data.id,
      result.data.creationDate,
      result.data.amount,
      result.data.inputCurrencyCode,
      result.data.amountInputCurrency,
      result.data.targetCurrencyCode,
      result.data.paySystemName
    )
  }
}

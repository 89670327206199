import cn from 'classnames'
import React, { forwardRef, ReactNode } from 'react'

import { InputSizeEnum, InputStyleTypeEnum } from './input.config'

import styles from './input.module.css'

type InputProps = {
  className?: string
  styleType?: InputStyleTypeEnum
  styleSize?: InputSizeEnum
  icon?: ReactNode
  after?: ReactNode
  error?: string
  withErrorText?: boolean
} & React.HTMLProps<HTMLInputElement>

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      icon,
      after,
      error,
      withErrorText = true,
      styleSize = InputSizeEnum.MEDIUM,
      styleType = InputStyleTypeEnum.PRIMARY,
      ...rest
    },
    ref
  ) => {
    const containerCls = cn(styles.container, className, {
      [styles.primary]: styleType === InputStyleTypeEnum.PRIMARY,
      [styles.secondary]: styleType === InputStyleTypeEnum.SECONDARY,
      [styles.medium]: styleSize === InputSizeEnum.MEDIUM,
      [styles.large]: styleSize === InputSizeEnum.LARGE,
      [styles.danger]: Boolean(error) === true,
      [styles.dangerText]: withErrorText === true && Boolean(error) === true,
      [styles.withIcon]: !!icon || !!after
    })
    return (
      <div className={containerCls}>
        <input className={styles.input} ref={ref} type={rest.type} {...rest} />
        {icon && icon}
        {after && after}
        {error && withErrorText && (
          <span className={styles.error}>{error}</span>
        )}
      </div>
    )
  }
)

import { Flex } from '@components/flex'
import { PanelInner } from '@components/panel-inner'
import { SkeletonBase } from '@components/skeleton-base'

export const DevelopersPageLoader = () => {
  return (
    <PanelInner fullHeight>
      <Flex direction="column" gap="xl">
        <SkeletonBase length="m" height="l" />
        <SkeletonBase length="m" height="l" />
        <SkeletonBase height="xl" />
        <SkeletonBase height="xxl" />
        <SkeletonBase height="xxl" />
      </Flex>
    </PanelInner>
  )
}

import cn from 'classnames'

import styles from './form-wrapper.module.css'

interface FormWrapperProps extends React.ComponentPropsWithoutRef<'div'> {}

export const FormWrapper = ({
  className,
  children,
  ...rest
}: FormWrapperProps) => {
  return (
    <div className={cn(styles.container, className)} {...rest}>
      {children}
    </div>
  )
}

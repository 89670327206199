export class ApiError extends Error {
  public readonly code: string
  public readonly message: string
  public readonly errors?: unknown

  constructor(code: string, message: string, errors?: unknown) {
    super(`${code}: ${message}`)
    this.code = code
    this.message = message
    this.errors = errors
  }
}

import { PanelInner } from '@components/panel-inner'

import { NewsList } from '@entities/server'
import { Trans, useTranslation } from 'react-i18next'
import styles from './main-page.module.css'

export const MainPage = () => {
  const {} = useTranslation('dashboard')
  const {} = useTranslation('error')
  const domainName = window.location.hostname

  return (
    <section className={styles.container}>
      <PanelInner className={styles.greet}>
        <h2 className={styles.title}>
          <Trans
            i18nKey={'paragraph-1'}
            ns="dashboard"
            values={{
              domainName: domainName ?? 'localhost'
            }}
          />
        </h2>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <Trans ns="dashboard" i18nKey={'paragraph-2'} />
          </li>
          <li className={styles.listItem}>
            <Trans ns="dashboard" i18nKey={'paragraph-3'} />
          </li>
          <li className={styles.listItem}>
            <Trans ns="dashboard" i18nKey={'paragraph-4'} />
          </li>
          <li className={styles.listItem}>
            <Trans ns="dashboard" i18nKey={'paragraph-5'} />
          </li>
        </ul>
      </PanelInner>
      <PanelInner className={styles.news}>
        <NewsList />
      </PanelInner>
    </section>
  )
}

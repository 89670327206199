import { useIsUserAuth } from '@shared/session'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { userProfileQuery, userSoftListQuery } from './profile.query'

export const useUserProfile = () => {
  const isUserAuth = useIsUserAuth()
  return useQuery({ ...userProfileQuery({ isUserAuth }) })
}

export const useInvalidateProfile = () => {
  const isUserAuth = useIsUserAuth()
  const queryClient = useQueryClient()

  return () =>
    queryClient.invalidateQueries({
      queryKey: userProfileQuery({ isUserAuth }).queryKey
    })
}

export const useUserSoftList = () => {
  const isUserAuth = useIsUserAuth()
  return useQuery({ ...userSoftListQuery({ isUserAuth }) })
}

export const useInvalidateUserSofts = () => {
  const isUserAuth = useIsUserAuth()
  const queryClient = useQueryClient()

  return () =>
    queryClient.invalidateQueries({
      queryKey: userSoftListQuery({ isUserAuth }).queryKey
    })
}

import { z } from 'zod'

export const OperatorSchema = z.object({
  id: z.number(),
  operatorId: z.number(),
  operatorName: z.string(),
  countryCode: z.string(),
  bringTime: z.number()
})

export const OperatorListSchema = z.array(OperatorSchema)
export const OperatorListRequestDTOSchema = z.object({
  countryCode: z.string(),
  bringTime: z.string()
})

import cn from 'classnames'

import styles from './skeleton-base.module.css'

interface SkeletonBaseProps extends React.ComponentPropsWithoutRef<'div'> {
  length?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl'
  height?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl'
}

export const SkeletonBase = ({
  className,
  length = 'xxl',
  height = 'm',
  ...rest
}: SkeletonBaseProps) => {
  return (
    <div
      className={cn(styles.container, className, {
        [styles.lengthXs]: length === 'xs',
        [styles.lengthS]: length === 's',
        [styles.lengthM]: length === 'm',
        [styles.lengthL]: length === 'l',
        [styles.lengthXl]: length === 'xl',
        [styles.lengthXxl]: length === 'xxl',

        [styles.heightXs]: height === 'xs',
        [styles.heightS]: height === 's',
        [styles.heightM]: height === 'm',
        [styles.heightL]: height === 'l',
        [styles.heightXl]: height === 'xl',
        [styles.heightXxl]: height === 'xxl'
      })}
      {...rest}
    ></div>
  )
}

export class RepositoryError extends Error {
  public readonly code: string
  public readonly message: string

  constructor(code: string, message: string) {
    super(`${code}: ${message}`)
    this.code = code
    this.message = message
  }
}

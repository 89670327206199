import { Flex } from '@components/flex'
import { SkeletonBase } from '@components/skeleton-base'

export const ProfileSettingsPageLoader = () => {
  return (
    <Flex direction="column">
      <SkeletonBase length="m" height="xl" />
      <SkeletonBase length="m" height="xxl" />
      <SkeletonBase length="m" height="xxl" />
      <SkeletonBase length="m" height="xxl" />
      <SkeletonBase length="m" height="xl" />
    </Flex>
  )
}

import { config } from '@shared/config'
import { ModelError } from '@shared/errors/model.error'
import { ProfileSchema } from '../profile.schema'

export class ProfileModel {
  constructor(
    public readonly id: string,
    public readonly created: Date,
    public readonly login: string,
    public readonly email: string,
    public readonly balance: number,
    public readonly apiKey: string,
    public readonly isMailVerified: boolean,
    public readonly isTelegramNotificationEnabled: boolean,
    public readonly telegramId: string | null,
    public readonly username: string | null,
    public readonly telegramName: string | null
  ) {}

  static fromAPI(data: unknown): ProfileModel {
    const result = ProfileSchema.safeParse(data)
    if (!result.success) {
      throw new ModelError('INVALID_DATA_FORMAT', result.error.errors)
    }
    return new ProfileModel(
      result.data.id,
      result.data.created,
      result.data.login,
      result.data.email,
      result.data.balance,
      result.data.apiKey,
      result.data.isMailVerified,
      result.data.isTelegramNotificationEnabled,
      result.data.telegramId,
      result.data.username,
      result.data.telegramName
    )
  }

  public getFormattedBalance() {
    return this.balance
      ? `${this.balance.toFixed(2)} ${config.getCurrency()}`
      : `0 ${config.getCurrency()}`
  }
}

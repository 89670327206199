import { Flex } from '@components/flex'
import { PanelInner } from '@components/panel-inner'
import { SkeletonBase } from '@components/skeleton-base'

export const MenuPageLoader = () => {
  return (
    <PanelInner fullHeight>
      <Flex
        direction="column"
        gap="lg"
        align="center"
        style={{
          paddingTop: '50px'
        }}
      >
        <SkeletonBase length="xs" height="xl" style={{ marginLeft: 'auto' }} />
        <SkeletonBase length="s" height="xl" />
        <SkeletonBase length="s" height="xl" />
        <SkeletonBase length="s" height="xl" />
        <SkeletonBase length="s" height="xl" />
        <SkeletonBase length="s" height="xl" />
        <SkeletonBase length="s" height="xl" />
      </Flex>
    </PanelInner>
  )
}

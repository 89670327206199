import cn from 'classnames'
import { memo } from 'react'
import ReactSelect, { GroupBase, Props } from 'react-select'

import styles from './style.module.css'

export const Select = memo(
  <
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
  >(
    props: Props<Option, IsMulti, Group> & {
      fullHeight?: boolean
      error?: string
      withErrorText?: boolean
    }
  ) => {
    const { fullHeight, error, withErrorText } = props
    const { className, ...rest } = props
    const containerCls = cn(styles.container, className, {
      [styles.danger]: Boolean(error) === true,
      [styles.dangerText]: withErrorText === true
    })
    const selectCls = cn(styles.select, {
      [styles.fullHeight]: fullHeight === true
    })
    return (
      <div className={containerCls}>
        <ReactSelect
          classNamePrefix={'select'}
          className={selectCls}
          menuPortalTarget={document.getElementById('react-select-portal')}
          menuShouldBlockScroll={true}
          classNames={{
            menuList: () => className ?? ''
          }}
          isSearchable={false}
          {...rest}
        />
        {error && withErrorText && (
          <span className={styles.error}>{error}</span>
        )}
      </div>
    )
  }
)

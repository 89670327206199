import styles from './news-item.module.css'

import cn from 'classnames'

interface NewsItemProps extends React.ComponentPropsWithoutRef<'div'> {
  news: string
}

export const NewsItem = ({ className, news, ...rest }: NewsItemProps) => {
  return (
    <div className={cn(styles.container, className)} {...rest}>
      <div dangerouslySetInnerHTML={{ __html: news }} />
    </div>
  )
}

import cn from 'classnames'
import { ReactComponent as Logo } from './assets/img/logo.svg'
import styles from './loader-root.module.css'

interface LoaderRootProps extends React.ComponentPropsWithoutRef<'div'> {}

export const LoaderRoot = ({ className, ...rest }: LoaderRootProps) => {
  return (
    <div className={cn(styles.container, className)} {...rest}>
      <Logo />
    </div>
  )
}

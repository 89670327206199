import { z } from 'zod'

export const CountrySchema = z.object({
  id: z.string(),
  countryCode: z.string(),
  countryName: z.string(),
  availableCount: z.number(),
  flagUrl: z.string(),
  isFavorite: z.boolean(),
  cost: z.number().nullable()
})

export const CountryListSchema = z.array(CountrySchema)

export const CountryListRequestDTOSchema = z.object({
  favoriteIds: z.array(z.string()),
  serviceCode: z.string().optional(),
  bringTime: z.string().optional() // '20m' | '4h'
})

const OperatorSchema = z.object({
  creationDate: z
    .string()
    .or(z.date())
    .transform((arg) => new Date(arg)),
  id: z.number(),
  name: z.string()
})

export const CountryWithOperatorSchema = z.object({
  countryCode: z.string(),
  countryName: z.string(),
  operators: z.array(OperatorSchema),
  countryOldCode: z.number().nullable()
})

export const CountryWithOperatorListSchema = z.array(CountryWithOperatorSchema)

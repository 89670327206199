import styles from './title.module.css'

import cn from 'classnames'

interface TitleProps extends React.ComponentPropsWithoutRef<'h1'> {
  size?: 'xs' | 'sm' | 'md' | 'lg'
  textStyle?: 'inactive' | 'primary' | 'inverse' | 'white' | 'accent'
  verticalIndent?: 'empty' | 'xs' | 'sm' | 'md' | 'lg'
  horizontalIndent?: 'empty' | 'xs' | 'sm' | 'md' | 'lg'
  textAlign?: 'center' | 'left' | 'right'
  bold?: boolean
}

export const Title = ({
  className,
  size = 'md',
  textStyle = 'primary',
  verticalIndent = 'empty',
  horizontalIndent = 'empty',
  textAlign = 'left',
  bold = false,
  ...rest
}: TitleProps) => {
  return (
    <h1
      className={cn(styles.container, className, {
        [styles.bold]: bold === true,
        [styles.left]: textAlign === 'left',
        [styles.center]: textAlign === 'center',
        [styles.right]: textAlign === 'right',
        [styles.verticalIndentEmpty]: verticalIndent === 'empty',
        [styles.verticalIndentXs]: verticalIndent === 'xs',
        [styles.verticalIndentSm]: verticalIndent === 'sm',
        [styles.verticalIndentMd]: verticalIndent === 'md',
        [styles.verticalIndentLg]: verticalIndent === 'lg',
        [styles.horizontalIndentEmpty]: horizontalIndent === 'empty',
        [styles.horizontalIndentXs]: horizontalIndent === 'xs',
        [styles.horizontalIndentSm]: horizontalIndent === 'sm',
        [styles.horizontalIndentMd]: horizontalIndent === 'md',
        [styles.horizontalIndentLg]: horizontalIndent === 'lg',
        [styles.xs]: size === 'xs',
        [styles.sm]: size === 'sm',
        [styles.md]: size === 'md',
        [styles.lg]: size === 'lg',
        [styles.primary]: textStyle === 'primary',
        [styles.inactive]: textStyle === 'inactive',
        [styles.inverse]: textStyle === 'inverse',
        [styles.white]: textStyle === 'white',
        [styles.accent]: textStyle === 'accent'
      })}
      {...rest}
    ></h1>
  )
}

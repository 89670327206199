import React from 'react'
import ReactDOM from 'react-dom'

type PortalProps = {
  children: React.ReactNode
}

export const Portal: React.FC<PortalProps> = ({ children }) => {
  const [container] = React.useState(() => document.createElement('div'))
  React.useEffect(() => {
    if (container) {
      document.body.appendChild(container)
    }
    return () => {
      document.body.removeChild(container)
    }
  }, [container])

  return ReactDOM.createPortal(children, container)
}


import { PaginationResponse } from '@shared/api/server/base'

export const getNextPageParam = <T>(
  pageParams: PaginationResponse<T>,
  pages: PaginationResponse<T>[]
): number | undefined => {
  if (pages.length < pageParams.meta.pageCount) {
    const a = pageParams.meta.page + 1
    return a
  }
  return undefined
}

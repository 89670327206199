import { authInstance, backendInstance } from './base'

const BASE_URL = '/auth'

export type SigninPayload = {
  username: string
  password: string
  captcha: string | null
}

export type SignupPayload = {
  email: string
  password: string
  captcha: string | null
}

export type SigninResponse = {
  accessToken: string
}

export type SignupResponse = {
  id: string
  username: string
}

export type ResetPasswordPayload = {
  username: string
}

export type ResetPasswordResponse = {
  result: string
}

export const signInCall = async (
  payload: SigninPayload
): Promise<SigninResponse> => {
  const response = await authInstance.post(`${BASE_URL}/signin`, {
    username: payload.username,
    password: payload.password,
    captcha: payload.captcha
  })
  return response.data
}

export const signUpCall = async (
  payload: SignupPayload
): Promise<SignupResponse> => {
  const response = await backendInstance.post(`${BASE_URL}/signup`, {
    email: payload.email,
    password: payload.password,
    captcha: payload.captcha
  })
  return response.data
}

export const signoutCall = async (): Promise<void> => {
  const response = await backendInstance.post(`${BASE_URL}/signout`)
  return response.data
}

export const resetPasswordCall = async (
  payload: ResetPasswordPayload
): Promise<ResetPasswordResponse> => {
  const response = await backendInstance.post(`${BASE_URL}/reset`, {
    username: payload.username
  })
  return response.data
}

export const refreshTokenCall = async (): Promise<SigninResponse> => {
  const response = await authInstance.post(`${BASE_URL}/refresh`)
  return response.data
}

import { ZodIssue } from 'zod'

export class ModelError extends Error {
  public readonly code: string
  public readonly errors?: ZodIssue[]

  constructor(code: string, errors?: ZodIssue[]) {
    super(`${code}: ${errors?.join(', ') ?? ''}`)
    this.code = code
    this.errors = errors
  }
}

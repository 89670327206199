import styles from './text.module.css'

import cn from 'classnames'

interface TextProps extends React.ComponentPropsWithoutRef<'span'> {
  size?: 'xs' | 'sm' | 'md' | 'lg'
  textStyle?:
    | 'inactive'
    | 'primary'
    | 'inverse'
    | 'white'
    | 'accent'
    | 'success'
  bold?: boolean
  textAlign?: 'left' | 'center' | 'right'
}

export const Text = ({
  className,
  size = 'md',
  textStyle = 'primary',
  bold = false,
  textAlign = 'left',
  ...rest
}: TextProps) => {
  return (
    <span
      className={cn(styles.container, className, {
        [styles.xs]: size === 'xs',
        [styles.sm]: size === 'sm',
        [styles.nm]: size === 'md',
        [styles.lg]: size === 'lg',
        [styles.bold]: bold === true,
        [styles.primary]: textStyle === 'primary',
        [styles.inactive]: textStyle === 'inactive',
        [styles.inverse]: textStyle === 'inverse',
        [styles.white]: textStyle === 'white',
        [styles.accent]: textStyle === 'accent',
        [styles.success]: textStyle === 'success',
        [styles.textLeft]: textAlign === 'left',
        [styles.textCenter]: textAlign === 'center',
        [styles.textRight]: textAlign === 'right'
      })}
      {...rest}
    >
      {rest.children}
    </span>
  )
}

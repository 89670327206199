import { backendInstance } from './base'

export const SERVICE_URL_KEY = 'service'
const BASE_URL = '/service'

export type ServiceResponse = {
  id: string
  serviceName: string
  serviceCode: string
  availableCount: number
  iconUrl: string
  isFavorite: boolean
  cost: number | null
  serviceOldCode: string
  bringTime: number
}

type ServiceRequest = {
  favoriteIds: string[]
  countryCode?: string
  bringTime?: string
  operatorId?: number
}

export const fetchServices = async (
  dto: ServiceRequest
): Promise<ServiceResponse[]> => {
  const response = await backendInstance.get(BASE_URL, {
    params: {
      ...(dto.countryCode && { countryCode: dto.countryCode }),
      favoriteIds: dto.favoriteIds,
      ...(dto.bringTime && { bringTime: dto.bringTime }),
      ...(dto.operatorId && { operatorId: dto.operatorId })
    }
  })
  return response.data
}

import { Flex } from '@components/flex'
import { PanelInner } from '@components/panel-inner'
import { SkeletonBase } from '@components/skeleton-base'

export const ForbiddenPageLoader = () => {
  return (
    <PanelInner fullHeight>
      <Flex
        direction="column"
        justify="center"
        align="center"
        style={{ height: '100%' }}
        gap="xl"
      >
        <SkeletonBase
          style={{ height: '10rem', borderRadius: '50%' }}
          length="s"
        />
        <SkeletonBase height="xl" length="s" />
        <SkeletonBase height="xl" length="m" />
      </Flex>
    </PanelInner>
  )
}

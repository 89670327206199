import styles from './flex.module.css'

import cn from 'classnames'

interface FlexProps extends React.ComponentPropsWithoutRef<'div'> {
  direction?: 'row' | 'column'
  justify?: 'start' | 'center' | 'end' | 'between' | 'around' | 'normal'
  align?: 'start' | 'center' | 'end' | 'normal'
  gap?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  verticalIndent?: 'empty' | 'xs' | 'sm' | 'md' | 'lg'
  horizontalIndent?: 'empty' | 'xs' | 'sm' | 'md' | 'lg'
  fullScreen?: boolean
}

export const Flex = ({
  className,
  direction = 'row',
  justify = 'normal',
  align = 'normal',
  verticalIndent = 'xs',
  horizontalIndent = 'xs',
  gap = 'sm',
  fullScreen = false,
  ...rest
}: FlexProps) => {
  return (
    <div
      className={cn(styles.container, className, {
        [styles.verticalIndentEmpty]: verticalIndent === 'empty',
        [styles.verticalIndentXs]: verticalIndent === 'xs',
        [styles.verticalIndentSm]: verticalIndent === 'sm',
        [styles.verticalIndentMd]: verticalIndent === 'md',
        [styles.verticalIndentLg]: verticalIndent === 'lg',
        [styles.horizontalIndentEmpty]: horizontalIndent === 'empty',
        [styles.horizontalIndentXs]: horizontalIndent === 'xs',
        [styles.horizontalIndentSm]: horizontalIndent === 'sm',
        [styles.horizontalIndentMd]: horizontalIndent === 'md',
        [styles.horizontalIndentLg]: horizontalIndent === 'lg',
        [styles.row]: direction === 'row',
        [styles.column]: direction === 'column',
        [styles.start]: justify === 'start',
        [styles.center]: justify === 'center',
        [styles.end]: justify === 'end',
        [styles.normal]: justify === 'normal',
        [styles.between]: justify === 'between',
        [styles.around]: justify === 'around',
        [styles.alignStart]: align === 'start',
        [styles.alignCenter]: align === 'center',
        [styles.alignEnd]: align === 'end',
        [styles.alignNormal]: align === 'normal',
        [styles.gapXs]: gap === 'xs',
        [styles.gapSm]: gap === 'sm',
        [styles.gapMd]: gap === 'md',
        [styles.gapLg]: gap === 'lg',
        [styles.gapXl]: gap === 'xl',
        [styles.fullScreen]: fullScreen === true
      })}
      {...rest}
    ></div>
  )
}

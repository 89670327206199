import { backendInstance } from './base'

const BASE_URL = '/user'

export type ChangePasswordPayload = {
  oldPassword: string
  newPassword: string
}

export type WithdrawPayload = {
  withdrawType: string
  requisite: string
  amount: number
}

export type PromocodePayload = {
  promo: string
}

export type UserProfileResponse = {
  id: string
  created: Date
  login: string
  email: string
  balance: number
  apiKey: string
  isMailVerified: boolean
  isTelegramNotificationEnabled: boolean
  referalBalance: number
  referalString: string
  telegramId: string | null
  username: string | null
  telegramName: string | null
}

export const fetchUserProfile = async (): Promise<UserProfileResponse> => {
  const response = await backendInstance.get(`${BASE_URL}/current/me`)
  return response.data
}

export const sendVerificationCodeCall = async (): Promise<void> => {
  const response = await backendInstance.post(`${BASE_URL}/verification`)
  return response.data
}

export const checkVerificationCodeCall = async ({
  code
}: {
  code: string
}): Promise<string> => {
  const response = await backendInstance.post(
    `${BASE_URL}/verification/check`,
    {
      code
    }
  )

  return response.data
}

export const changePasswordCall = async (
  passwords: ChangePasswordPayload
): Promise<void> => {
  const response = await backendInstance.patch(
    `${BASE_URL}/password/change`,
    passwords
  )
  return response.data
}

export const changeTgNotificationValue = async (
  newValue: boolean
): Promise<void> => {
  const response = await backendInstance.patch(`${BASE_URL}/profile`, {
    isTelegramNotificationEnabled: newValue
  })
  return response.data
}

export const unlinkTelegram = async (): Promise<void> => {
  const response = await backendInstance.patch(`${BASE_URL}/profile/unlink-tg`)
  return response.data
}

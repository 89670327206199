import classNames from 'classnames'
import React, { forwardRef } from 'react'

import styles from './checkbox.module.css'

interface CheckboxProps extends React.ComponentPropsWithoutRef<'input'> {
  title: string
  hasError?: boolean
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, title, hasError = false, ...props }, ref) => {
    const containerCls = classNames(styles.wrapper, {
      [`${className}`]: className,
      [styles.danger]: hasError === true
    })
    return (
      <div className={containerCls}>
        <label className={styles.label}>
          <input
            type="checkbox"
            className={styles.checkbox}
            ref={ref}
            {...props}
          />
          <span className={styles.title}>{title}</span>
        </label>
      </div>
    )
  }
)

import { PanelInner } from '@components/panel-inner'

import { Flex } from '@components/flex'
import { SkeletonBase } from '@components/skeleton-base'
import { useCurrentService } from '@entities/service'

export const ServiceBlockLoader = ({
  isServicesFirst,
  className
}: {
  isServicesFirst: boolean
  className?: string
}) => {
  const { currentService } = useCurrentService()
  const isMinimizePanel = isServicesFirst && currentService !== undefined

  const fullContent = (
    <PanelInner className={className}>
      <Flex direction="column">
        <Flex>
          <SkeletonBase length="s" height="l" />
          <SkeletonBase length="s" height="l" />
          <SkeletonBase length="s" height="l" />
        </Flex>
        <SkeletonBase height="xl" />
        <Flex direction="column">
          <SkeletonBase height="xxl" />
          <SkeletonBase height="xxl" />
          <SkeletonBase height="xxl" />
          <SkeletonBase height="xxl" />
          <SkeletonBase height="xxl" />
          <SkeletonBase height="xxl" />
          <SkeletonBase height="xxl" />
          <SkeletonBase height="xxl" />
          <SkeletonBase height="xxl" />
          <SkeletonBase height="xxl" />
        </Flex>
      </Flex>
    </PanelInner>
  )

  const minimizeContent = (
    <PanelInner className={className}>
      <Flex>
        <SkeletonBase height="xxl" length="s" />
        <SkeletonBase height="xxl" length="s" />
        <SkeletonBase height="xxl" length="s" />
        <SkeletonBase height="xxl" length="s" />
      </Flex>
    </PanelInner>
  )

  return isMinimizePanel ? minimizeContent : fullContent
}

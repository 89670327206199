import { Flex } from '@components/flex'
import { SkeletonBase } from '@components/skeleton-base'

export const NavbarDesktopLoader = () => {
  return (
    <Flex
      direction="column"
      gap="lg"
      style={{
        width: '220px',
        height: '100%',
        paddingTop: '50px'
      }}
    >
      <SkeletonBase style={{ height: '2rem' }} />
      <SkeletonBase style={{ height: '2rem' }} />
      <SkeletonBase style={{ height: '2rem' }} />
      <SkeletonBase style={{ height: '2rem' }} />
      <SkeletonBase style={{ height: '2rem' }} />
    </Flex>
  )
}

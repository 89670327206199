import { historyApi } from '@shared/api/server'
import {
  fetchDepositByCouponRequest,
  fetchDepositByGarantexRequest,
  fetchDepositMethods,
  fetchDepositRequest,
  fetchDepositServices
} from '@shared/api/server/deposit'
import { ApiError } from '@shared/errors/api.error'
import { ModelError } from '@shared/errors/model.error'
import { RepositoryError } from '@shared/errors/repository.error'
import {
  DepositByCouponRequestDTOResponseSchema,
  DepositByGarantexCouponRequestDTOResponseSchema,
  DepositRequestDTOResponseSchema
} from '../deposit.schema'
import {
  DepositByCouponRequestDTORequest,
  DepositByCouponRequestDTOResponse,
  DepositByGarantexCouponRequestDTORequest,
  DepositByGarantexCouponRequestDTOResponse,
  DepositHistoryListRequestDTO,
  DepositHistoryListResponseDTO,
  DepositMethodRequestDTO,
  DepositMethodResponseDTO,
  DepositRequestDTORequest,
  DepositRequestDTOResponse,
  DepositServiceRequestDTO,
  DepositServiceResponseDTO
} from '../deposit.types'
import { DepositMethodModel } from './deposit-method.model'
import { DepositRecordModel } from './deposit-record.model'
import { DepositServiceModel } from './deposit-service.model'

export class DepositRepository {
  async getHistoryList(
    dto: DepositHistoryListRequestDTO,
    page: number
  ): Promise<DepositHistoryListResponseDTO> {
    try {
      const depositHistoryResponse = await historyApi.fetchHistoryDeposit({
        ...dto,
        page: page || 0,
        count: dto.count || 50
      })
      const data = depositHistoryResponse.data.map((number) =>
        DepositRecordModel.fromAPI(number)
      )

      return {
        data,
        meta: depositHistoryResponse.meta
      }
    } catch (error) {
      if (error instanceof ModelError) {
        console.log(
          'catch: ',
          error.errors
            ?.map((error) => `${error.path} - ${error.message}`)
            .join(',')
        )
        throw new RepositoryError(error.code, error.message)
      } else if (error instanceof ApiError) {
        throw new RepositoryError(error.code, error.message)
      } else {
        throw error
      }
    }
  }

  async getMethodList(
    dto: DepositMethodRequestDTO
  ): Promise<DepositMethodResponseDTO> {
    try {
      const methodListResponse = await fetchDepositMethods(dto)
      const data = methodListResponse.map((method) => {
        const { paymentServices, ...rest } = method
        const methodObject = DepositMethodModel.fromAPI(rest)
        const services = paymentServices.map((service) =>
          DepositServiceModel.fromAPI(service)
        )
        return {
          ...methodObject,
          paymentServices: services
        }
      })
      return data
    } catch (error) {
      if (error instanceof ModelError) {
        console.log('catch: ', error.errors?.join(', '))
        throw new RepositoryError(error.code, error.message)
      } else if (error instanceof ApiError) {
        throw new RepositoryError(error.code, error.message)
      } else {
        throw error
      }
    }
  }
  async getServiceList(
    dto: DepositServiceRequestDTO
  ): Promise<DepositServiceResponseDTO> {
    try {
      const serviceListResponse = await fetchDepositServices(dto)
      const data = serviceListResponse.map((method) => {
        const { paymentMethods, ...rest } = method
        const serviceObject = DepositServiceModel.fromAPI(rest)
        const methods = paymentMethods.map((method) =>
          DepositMethodModel.fromAPI(method)
        )
        return {
          ...serviceObject,
          paymentMethods: methods
        }
      })
      return data
    } catch (error) {
      if (error instanceof ModelError) {
        console.log('catch: ', error.errors?.join(', '))
        throw new RepositoryError(error.code, error.message)
      } else if (error instanceof ApiError) {
        throw new RepositoryError(error.code, error.message)
      } else {
        throw error
      }
    }
  }
  async deposit(
    dto: DepositRequestDTORequest
  ): Promise<DepositRequestDTOResponse> {
    try {
      const response = await fetchDepositRequest(dto)

      const result = DepositRequestDTOResponseSchema.safeParse(response)
      if (!result.success) {
        throw new ModelError('INVALID_DATA_FORMAT', result.error.errors)
      }
      return result.data
    } catch (error) {
      if (error instanceof ModelError) {
        console.log('catch: ', error.errors?.join(', '))
        throw new RepositoryError(error.code, error.message)
      } else if (error instanceof ApiError) {
        throw new RepositoryError(error.code, error.message)
      } else {
        throw error
      }
    }
  }
  async depositByCoupon(
    dto: DepositByCouponRequestDTORequest
  ): Promise<DepositByCouponRequestDTOResponse> {
    try {
      const response = await fetchDepositByCouponRequest(dto)

      const result = DepositByCouponRequestDTOResponseSchema.safeParse(response)
      if (!result.success) {
        throw new ModelError('INVALID_DATA_FORMAT', result.error.errors)
      }
      return result.data
    } catch (error) {
      if (error instanceof ModelError) {
        console.log('catch: ', error.errors?.join(', '))
        throw new RepositoryError(error.code, error.message)
      } else if (error instanceof ApiError) {
        throw new RepositoryError(error.code, error.message)
      } else {
        throw error
      }
    }
  }
  async depositByGarantexCoupon(
    dto: DepositByGarantexCouponRequestDTORequest
  ): Promise<DepositByGarantexCouponRequestDTOResponse> {
    try {
      const response = await fetchDepositByGarantexRequest(dto)

      const result =
        DepositByGarantexCouponRequestDTOResponseSchema.safeParse(response)
      if (!result.success) {
        throw new ModelError('INVALID_DATA_FORMAT', result.error.errors)
      }
      return result.data
    } catch (error) {
      if (error instanceof ModelError) {
        console.log('catch: ', error.errors?.join(', '))
        throw new RepositoryError(error.code, error.message)
      } else if (error instanceof ApiError) {
        throw new RepositoryError(error.code, error.message)
      } else {
        throw error
      }
    }
  }
}

import { backendInstance } from './base'

const BASE_URL = '/payment'

export type DepositMethod = {
  id: number
  commissionPercent: number | null
  currency: string
  paymentMethod: string
  paymentMethodDisplayName: string
  paymentServiceId: number
}

export type DepositService = {
  id: number
  name: string
  paymentServiceURL: string
}

export type DepositMethodWithServices = DepositMethod & {
  paymentServices: DepositService[]
}
export type DepositServiceWithMethods = DepositService & {
  paymentMethods: DepositMethod[]
}

export type DepositServicesDTORequest = {
  currency?: string
  paymentMethod?: string
}
export type DepositServicesDTOResponse = DepositServiceWithMethods[]

export const fetchDepositServices = async (
  payload: DepositServicesDTORequest
): Promise<DepositServicesDTOResponse> => {
  const response = await backendInstance.get(`${BASE_URL}/service`, {
    params: payload,
  })
  return response.data
}

export type DepositMethodsDTORequest = {
  currency?: string
  paymentMethod?: string
}
export type DepositMethodsDTOResponse = DepositMethodWithServices[]

export const fetchDepositMethods = async (
  payload: DepositMethodsDTORequest
): Promise<DepositMethodsDTOResponse> => {
  const response = await backendInstance.get(`${BASE_URL}/method`, {
    params: payload,
  })
  return response.data
}

// #region TOPUP REQUEST
export type PayRequestDTORequest = {
  sourceNetwork: string
  sourceCurrency: string
  targetCurrency: string
  targetValue: number
  paymentSystem: string
}
export type PayRequestDTOResponse = {
  payHistoryId: string
  redirectUrl: string
  webhookUrl: string
}

export const fetchDepositRequest = async (
  payload: PayRequestDTORequest
): Promise<PayRequestDTOResponse> => {
  const response = await backendInstance.post(`${BASE_URL}/service/topup`, {
    ...payload,
    paymentSystem: payload.paymentSystem.toLowerCase(),
  })
  return response.data
}

// #endregion

// #endregion

// #region COUPON PAY REQUEST
export type PayCouponDTORequest = {
  code: string
}
export type PayCouponDTOResponse = {
  result: string
}

export const fetchDepositByCouponRequest = async (
  payload: PayCouponDTORequest
): Promise<PayCouponDTOResponse> => {
  const response = await backendInstance.post(`${BASE_URL}/coupon`, {
    ...payload,
  })
  return response.data
}

// #endregion

// #region GARANTEX PAY REQUEST
export type PayGarantexDTORequest = {
  code: string
}
export type PayGarantexDTOResponse = {
  result: string
}

export const fetchDepositByGarantexRequest = async (
  payload: PayGarantexDTORequest
): Promise<PayGarantexDTOResponse> => {
  const response = await backendInstance.post(`${BASE_URL}/garantex`, {
    ...payload,
  })
  return response.data
}

// #endregion


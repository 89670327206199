import { useQuery, useQueryClient } from '@tanstack/react-query'
import { telegramNewsListQuery } from './server.query'

export const useTelegramNewsList = () => {
  return useQuery({ ...telegramNewsListQuery() })
}

export const useInvalidateTelegramNewsList = () => {
  const queryClient = useQueryClient()

  return () =>
    queryClient.invalidateQueries({
      queryKey: telegramNewsListQuery().queryKey
    })
}

import { Fragment, ReactNode, useEffect, useState } from 'react'

import { LoaderRoot } from '@components/loader-root'
import { useLoadDepositsFilter } from '@entities/deposit'
import { useCheckAuthAsync } from '@shared/session'

export const DataProvider = ({ children }: { children: ReactNode }) => {
  const { refreshSession, isLoading: isRefreshing } = useCheckAuthAsync()
  const { loadFilters } = useLoadDepositsFilter()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true)

      refreshSession()
      loadFilters()

      setIsLoading(false)
    }

    initialize()
  }, [refreshSession, loadFilters])

  if (isLoading || isRefreshing) {
    return <LoaderRoot />
  }

  return <Fragment>{children}</Fragment>
}

import { PAGES } from '@shared/config/constants'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { NavLink, Outlet } from 'react-router-dom'

import { PanelInner } from '@components/panel-inner'

import { DepositIcon } from '@components/icons/deposit-icon'
import { SettingsIcon } from '@components/icons/settings-icon'
import { UserFilledIcon } from '@components/icons/user-filled-icon'
import { WithdrawIcon } from '@components/icons/withdraw-icon'
import styles from './office-page.module.css'

export const OfficePage = () => {
  const { t } = useTranslation('common')
  return (
    <PanelInner className={styles.container}>
      <div className={styles.tabs}>
        <NavLink
          to={PAGES.PK_PAGE.PROFILE_PAGE}
          className={({ isActive }) =>
            isActive ? cn(styles.link, styles.linkActive) : styles.link
          }
        >
          <span className={styles.linkText}>{t('links.profile')}</span>
          <UserFilledIcon
            title={t('links.profile')}
            className={styles.linkIcon}
          />
        </NavLink>
        <NavLink
          to={PAGES.PK_PAGE.TOPUP_HISTORY_PAGE}
          className={({ isActive }) =>
            isActive ? cn(styles.link, styles.linkActive) : styles.link
          }
          relative="route"
        >
          <span className={styles.linkText}>{t('links.topup-history')}</span>
          <DepositIcon
            title={t('links.topup-history')}
            className={styles.linkIcon}
          />
        </NavLink>
        <NavLink
          to={PAGES.PK_PAGE.WITHDRAW_HISTORY_PAGE}
          className={({ isActive }) =>
            isActive ? cn(styles.link, styles.linkActive) : styles.link
          }
          relative="route"
        >
          <span className={styles.linkText}>{t('links.withdraw-history')}</span>
          <WithdrawIcon
            title={t('links.withdraw-history')}
            className={styles.linkIcon}
          />
        </NavLink>
        <NavLink
          to={PAGES.PK_PAGE.SETTINGS}
          className={({ isActive }) =>
            isActive ? cn(styles.link, styles.linkActive) : styles.link
          }
          relative="route"
        >
          <span className={styles.linkText}>{t('links.settings')}</span>
          <SettingsIcon
            title={t('links.settings')}
            className={styles.linkIcon}
          />
        </NavLink>
      </div>
      <Outlet />
    </PanelInner>
  )
}

import classNames from 'classnames'

import styles from './loader-circle.module.css'

interface LoaderCircleProps extends React.ComponentPropsWithoutRef<'div'> {
  size?: 'xs' | 'sm' | 'md' | 'lg'
}

export const LoaderCircle = ({
  className,
  size = 'xs',
  ...rest
}: LoaderCircleProps) => {
  const ringPrfx = styles.ring
  const ringCls = classNames(ringPrfx, className, {
    [styles.xs]: size === 'xs',
    [styles.sm]: size === 'sm',
    [styles.md]: size === 'md',
    [styles.lg]: size === 'lg'
  })
  return (
    <div className={ringCls} {...rest}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

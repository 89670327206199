import { ModelError } from '@shared/errors/model.error'
import { SoftSchema } from '../profile.schema'

export class SoftModel {
  constructor(
    public readonly softId: string,
    public readonly balance: number,
    public readonly creationDate: Date,
    public readonly incomePercent: number,
    public readonly softName: string
  ) {}

  static fromAPI(data: unknown): SoftModel {
    const result = SoftSchema.safeParse(data)
    if (!result.success) {
      throw new ModelError('INVALID_DATA_FORMAT', result.error.errors)
    }
    return new SoftModel(
      result.data.softId,
      result.data.balance,
      result.data.creationDate,
      result.data.incomePercent,
      result.data.softName
    )
  }
}

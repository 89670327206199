import { Footer } from '@components/footer'
import { Header } from '@widgets/header'
import { HeaderLoader } from '@widgets/header/header.loader'
import { NavbarDesktop } from '@widgets/navbar'
import { NavbarDesktopLoader } from '@widgets/navbar/ui/navbar-desktop/navbar-desktop.loader'
import { Suspense } from 'react'
import { Outlet } from 'react-router'
import { GetParameterPopup } from '../../app/lib/get-parameter-popup'
import styles from './layout-page.module.css'

interface LayoutPageProps extends React.ComponentPropsWithoutRef<'section'> {}

export const LayoutPage = ({ ...rest }: LayoutPageProps) => {
  return (
    <section className={styles.container} {...rest}>
      <Suspense fallback={<HeaderLoader />}>
        <Header className={styles.header} />
      </Suspense>
      <div className={styles.body}>
        <Suspense fallback={<NavbarDesktopLoader />}>
          <NavbarDesktop className={styles.navbar} />
        </Suspense>
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
      <Footer className={styles.footer} />
      <GetParameterPopup />
    </section>
  )
}

import { ANIMATION_TIME } from '@shared/layouts/popup'
import { Portal } from '@shared/layouts/portal/portal'
import { useCallback, useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

import cn from 'classnames'
import animationStyles from './popup-inner.animtaion.module.css'
import styles from './popup-inner.module.css'

interface PopupInnerProps extends React.ComponentPropsWithoutRef<'div'> {
  onClose: () => void
  opened?: boolean
  withClose?: boolean
  withCenter?: boolean
}

const overlayAnimation = {
  enter: animationStyles.overlayEnter,
  enterActive: animationStyles.overlayEnterActive,
  exit: animationStyles.overlayExit,
  exitActive: animationStyles.overlayExitActive
}

const contentAnimation = {
  enter: animationStyles.contentEnter,
  enterActive: animationStyles.contentEnterActive,
  exit: animationStyles.contentExit,
  exitActive: animationStyles.contentExitActive
}

export const PopupInner = ({
  onClose,
  children,
  withClose = true,
  withCenter = false,
  opened = false,
  ...rest
}: PopupInnerProps) => {
  const overlayRef = useRef(null)
  const contentRef = useRef(null)

  const [animationIn, setAnimationIn] = useState(false)

  useEffect(() => {
    setAnimationIn(opened)
  }, [opened])

  const handleEscClick = useCallback(
    (e: any) => {
      if ((e.charCode || e.keyCode) === 27) {
        onClose()
      }
    },
    [onClose]
  )
  useEffect(() => {
    document.body.addEventListener('keydown', handleEscClick)
    return function cleanup() {
      document.body.removeEventListener('keydown', handleEscClick)
    }
  }, [handleEscClick])

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])
  return (
    <Portal>
      <div className={styles.popup} role="dialog" {...rest}>
        <CSSTransition
          in={animationIn}
          nodeRef={overlayRef}
          timeout={ANIMATION_TIME}
          mountOnEnter
          unmountOnExit
          classNames={overlayAnimation}
        >
          <div
            ref={overlayRef}
            className={styles.overlay}
            role="button"
            tabIndex={0}
            onClick={onClose}
          />
        </CSSTransition>
        {withClose && (
          <div
            className={styles.close}
            role="button"
            tabIndex={1}
            onClick={onClose}
          />
        )}
        <CSSTransition
          in={animationIn}
          nodeRef={contentRef}
          timeout={ANIMATION_TIME}
          mountOnEnter
          unmountOnExit
          classNames={contentAnimation}
        >
          <div
            ref={contentRef}
            className={cn(styles.content, { [styles.center]: withCenter })}
          >
            {children}
          </div>
        </CSSTransition>
      </div>
    </Portal>
  )
}

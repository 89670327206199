import { useLayout } from '@shared/model/layout'
import { CountriesBlockLoader } from '@widgets/countries-block'
import { ServiceBlockLoader } from '@widgets/services-block'
import cn from 'classnames'
import styles from './buy-numbers-page.module.css'

export const BuyNumbersPageLoader = () => {
  const {
    hasOnlyTopPanel,
    hasDoublePanels,
    hasAllPanels,
    isServicesFirst,
    isCountriesVisible,
    isServicesVisible
  } = useLayout()

  return (
    <div
      className={cn(styles.container, {
        [styles.reverse]: isServicesFirst === false,
        [styles.one]: hasOnlyTopPanel === true,
        [styles.double]: hasDoublePanels === true,
        [styles.full]: hasAllPanels
      })}
    >
      {isCountriesVisible && (
        <CountriesBlockLoader
          className={styles.bottom}
          isCountriesFirst={isServicesFirst === false}
        />
      )}
      {isServicesVisible && (
        <ServiceBlockLoader
          className={styles.top}
          isServicesFirst={isServicesFirst}
        />
      )}
    </div>
  )
}

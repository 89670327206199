import { countryApi } from '@shared/api/server'
import { ApiError } from '@shared/errors/api.error'
import { ModelError } from '@shared/errors/model.error'
import { RepositoryError } from '@shared/errors/repository.error'
import { CountryListRequestDTO } from '../country.types'
import { CountryOperatorModel } from './country-operator.model'
import { CountryModel } from './country.model'

export class CountryRepository {
  async getList(dto: CountryListRequestDTO): Promise<CountryModel[]> {
    try {
      const countryResponse = await countryApi.fetchCountries(dto)
      const data = countryResponse.map((country) =>
        CountryModel.fromAPI(country)
      )

      return data
    } catch (error) {
      if (error instanceof ModelError) {
        console.log(
          'catch: ',
          error.errors
            ?.map((error) => `${error.path} - ${error.message}`)
            .join(',')
        )
        throw new RepositoryError(error.code, error.message)
      } else if (error instanceof ApiError) {
        throw new RepositoryError(error.code, error.message)
      } else {
        throw error
      }
    }
  }

  async getListWithOperators(): Promise<CountryOperatorModel[]> {
    try {
      const countryWithOperatorsResponse =
        await countryApi.fetchCountriesWithOperators()
      const data = countryWithOperatorsResponse.map((country) =>
        CountryOperatorModel.fromAPI(country)
      )
      return data
    } catch (error) {
      if (error instanceof ModelError) {
        console.log(
          'catch: ',
          error.errors
            ?.map((error) => `${error.path} - ${error.message}`)
            .join(',')
        )
        throw new RepositoryError(error.code, error.message)
      } else if (error instanceof ApiError) {
        throw new RepositoryError(error.code, error.message)
      } else {
        throw error
      }
    }
  }
}

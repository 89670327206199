import { Flex } from '@components/flex'
import { useTelegramNewsList } from '../../model/store/server.hooks'
import { NewsItem } from '../news-item'
import styles from './news-list.module.css'

import { LoaderCircle } from '@components/loader-circle'
import cn from 'classnames'

interface NewsListProps extends React.ComponentPropsWithoutRef<'div'> {}

export const NewsList = ({ className, ...rest }: NewsListProps) => {
  const { data, isLoading } = useTelegramNewsList()
  if (isLoading) return <LoaderCircle />
  if (!data || data.length === 0) return null

  return (
    <Flex
      direction="column"
      gap="md"
      className={cn(styles.container, className)}
      {...rest}
    >
      {data.map((news, idx) => (
        <NewsItem key={idx} className={styles.news} news={news} />
      ))}
    </Flex>
  )
}

import { z } from 'zod'

export const ProfileSchema = z.object({
  apiKey: z.string(),
  balance: z.number(),
  created: z
    .string()
    .or(z.date())
    .transform((arg) => new Date(arg)),
  email: z.string(),
  id: z.string(),
  isMailVerified: z.boolean(),
  isTelegramNotificationEnabled: z.boolean(),
  login: z.string(),
  telegramId: z.string().nullable(),
  username: z.string().nullable(),
  telegramName: z.string().nullable()
})

export const ChangePasswordDTOSchema = z.object({
  oldPassword: z.string(),
  newPassword: z.string()
})

export const SoftSchema = z.object({
  balance: z.number(),
  creationDate: z
    .string()
    .or(z.date())
    .transform((arg) => new Date(arg)),
  incomePercent: z.number(),
  softId: z.string(),
  softName: z.string()
})

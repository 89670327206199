import { Flex } from '@components/flex'
import { PanelInner } from '@components/panel-inner'
import { SkeletonBase } from '@components/skeleton-base'

export const VerificationCodePageLoader = () => {
  return (
    <PanelInner fullHeight>
      <Flex fullScreen align="center" justify="center">
        <SkeletonBase height="xl" />
      </Flex>
    </PanelInner>
  )
}

import { Popup } from '@shared/layouts/popup'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@components/button'
import { WithdrawForm } from '../withdraw-form'

interface WithdrawModalProps extends React.ComponentPropsWithoutRef<'button'> {
  isOpened?: boolean
}

export const WithdrawModal = ({
  isOpened = false,
  ...rest
}: WithdrawModalProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(isOpened)
  const { t } = useTranslation('deposit')
  const onClick = () => {
    setIsVisible((prev) => !prev)
  }
  // const { data } = useUserProfile()
  // const referalBalance = data?.referalBalance ?? 0
  return (
    <>
      <Button
        styleSize={'sm'}
        onClick={onClick}
        // disabled={referalBalance < 500}
        title={t('notifications.widraw-min-value') ?? ''}
        {...rest}
      >
        {t('buttons:withdraw')}
      </Button>
      <Popup onClose={() => setIsVisible(false)} isOpened={isVisible}>
        <WithdrawForm />
      </Popup>
    </>
  )
}

import { ModelError } from '@shared/errors/model.error'
import { NewsSchema } from './server.schema'

export const parseTelegramNews = (data: unknown): string => {
  const result = NewsSchema.safeParse(data)
  if (!result.success) {
    throw new ModelError('INVALID_DATA_FORMAT', result.error.errors)
  }
  return result.data
}

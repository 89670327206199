import { WorkModeType, isValidMode } from './enums'

class Config {
  private readonly staticUrl: string
  private readonly apiUrl: string
  private readonly currency: string
  private readonly botName: string
  private readonly appMode: WorkModeType
  private readonly recaptchaKey: string
  private readonly workEnvironment: WorkModeType

  constructor() {
    this.staticUrl = import.meta.env.VITE_STATIC_URL || ''
    this.apiUrl = import.meta.env.VITE_API_URL || ''
    this.currency = import.meta.env.VITE_CURRENCY || ''
    this.botName = import.meta.env.VITE_BOT_NAME || ''
    this.appMode = (import.meta.env.NODE_ENV as WorkModeType) || null
    this.recaptchaKey = import.meta.env.VITE_RECAPTCHA_KEY || ''
    this.workEnvironment =
      (import.meta.env.VITE_APP_MODE as WorkModeType) || null

    if (!this.staticUrl) {
      throw new Error('Missing required environment variable: STATIC_URL')
    }
    if (!this.apiUrl) {
      throw new Error('Missing required environment variable: API_URL')
    }
    if (!this.currency) {
      throw new Error('Missing required environment variable: CURRENCY')
    }
    if (!this.botName) {
      throw new Error('Missing required environment variable: BOT_NAME')
    }
    if (!this.appMode || !isValidMode(this.appMode)) {
      this.appMode = 'development'
    }
    if (!this.workEnvironment || !isValidMode(this.workEnvironment)) {
      this.workEnvironment = 'development'
    }
    if (!this.recaptchaKey) {
      if (this.workEnvironment === 'production') {
        throw new Error('Missing required environment variable: RECAPTCHA_KEY')
      }
      this.recaptchaKey = ''
    }
  }

  getStaticUrl(): string {
    return this.staticUrl
  }
  getApiUrl(): string {
    return this.apiUrl
  }
  getCurrency(): string {
    return this.currency
  }
  getBotName(): string {
    return this.botName
  }
  getAppMode(): WorkModeType {
    return this.appMode as WorkModeType
  }
  isDevAppMode(): boolean {
    return this.appMode === 'development'
  }
  getRecaptchaKey(): string {
    return this.recaptchaKey
  }
  getWorkEnvironment(): WorkModeType {
    return this.workEnvironment as WorkModeType
  }
}

const config = new Config()

export default config

import { ModelError } from '@shared/errors/model.error'
import { CountryWithOperatorSchema } from '../country.schema'

export class CountryOperatorModel {
  constructor(
    public readonly countryCode: string,
    public readonly countryName: string,
    public readonly operators: Array<{
      creationDate: string | Date
      id: number
      name: string
    }>,
    public readonly countryOldCode: number | null
  ) {}

  static fromAPI(data: unknown): CountryOperatorModel {
    const result = CountryWithOperatorSchema.safeParse(data)
    if (!result.success) {
      throw new ModelError('INVALID_DATA_FORMAT', result.error.errors)
    }
    return new CountryOperatorModel(
      result.data.countryCode,
      result.data.countryName,
      result.data.operators,
      result.data.countryOldCode
    )
  }
}

import axios, { AxiosInstance } from 'axios'

import { config } from '../../config'

export const backendInstance: AxiosInstance = axios.create({
  baseURL: config.getApiUrl(),
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Authorization: ''
  },
  timeout: 180000
})

export const authInstance: AxiosInstance = axios.create({
  baseURL: config.getApiUrl(),
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 180000
})

export type PageMetaType = {
  page: number
  count: number
  itemCount: number
  pageCount: number
  hasPreviousPage: boolean
  hasNextPage: boolean
}

export type PaginationResponse<T> = {
  data: T
  meta: PageMetaType
}

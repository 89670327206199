import { z } from 'zod'

export const NumberStatusSchema = z.enum([
  'PurchaseSuccess',
  'CancelByUserSuccess',
  'CancelBannedSuccess',
  'CancelledByTimeout',
  'SmsReceived',
  'MoreSmsSuccess'
])

export const NumberSchema = z.object({
  id: z.number(),
  creationDate: z
    .string()
    .or(z.date())
    .transform((arg) => new Date(arg)),
  countryCode: z.string(),
  serviceName: z.string(),
  countryIcon: z.string(),
  operatorName: z.string(),
  serviceCode: z.string(),
  telNumber: z.string(),
  telPrefix: z.string(),
  cost: z.number(),
  isProlongation: z.boolean(),
  canProlong: z.boolean(),
  lastStatus: NumberStatusSchema,
  rentStopDate: z
    .string()
    .or(z.date())
    .transform((arg) => new Date(arg)),
  smsCode: z.string().optional()
})

export const NumberListSchema = z.array(NumberSchema)

export const NumberActiveListRequestDTOSchema = z.object({
  // page: z.number().min(0),
  count: z.number().min(0).default(50)
})

export const NumberHistoryListRequestDTOSchema = z.object({
  count: z.number().min(0).default(50),
  sortField: z.string().optional(),
  sortType: z.string().optional(),
  status: z.string().optional(),
  phone: z.string().optional(),
  startDt: z.string().optional(),
  endDt: z.string().optional(),
  onlyArchive: z.boolean().optional()
})

export const NumberBuyBtnPayloadSchema = z.object({
  countryCode: z.string(),
  operatorId: z.number().optional(),
  serviceCode: z.string()
})

export const NumberBuyRequestDTOSchema = z.object({
  countryCode: z.string(),
  operatorId: z.number().optional(),
  serviceCode: z.string(),
  rentTime: z.string()
})
export const NumberBuyResponseDTOSchema = z.object({
  tel: z.string()
})

export const NumberProlongRequestDTOSchema = z.object({
  serviceCode: z.string(),
  phoneNumber: z.string()
})
export const NumberProlongResponseDTOSchema = z.object({
  newPurchaseInnerId: z.number(),
  oldPurchaseInnerId: z.number(),
  phone: z.string()
})

export const NumberCancelRequestDTOSchema = z.object({
  phoneNumber: z.string(),
  serviceCode: z.string()
})
export const NumberCancelResponseDTOSchema = z.object({
  phone: z.string(),
  purchaseId: z
    .number()
    .or(z.string())
    .transform((arg) => arg.toString()),
  status: z.string()
})

export const NumberBanRequestDTOSchema = z.object({
  phoneNumber: z.string(),
  serviceCode: z.string()
})
export const NumberBanResponseDTOSchema = z.object({
  phone: z.string(),
  purchaseId: z
    .number()
    .or(z.string())
    .transform((arg) => arg.toString()),
  status: z.string()
})

export const ExtraSMSRequestDTOSchema = z.object({
  number: z.string(),
  serviceCode: z.string()
})
export const ExtraSMSResponseDTOSchema = z.object({
  phone: z.string(),
  purchaseId: z
    .number()
    .or(z.string())
    .transform((arg) => arg.toString()),
  status: z.string()
})

import { serviceRepository } from '../domain'
import { ServiceListRequestDTO } from '../schema.types'

const SERVICE_QUERY_KEY = 'service'

export const serviceListQuery = (dto: ServiceListRequestDTO) => {
  return {
    queryKey: [SERVICE_QUERY_KEY, { ...dto }],
    queryFn: () => serviceRepository.getList(dto),
    staleTime: 3600000,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  }
}

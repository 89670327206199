import { Flex } from '@components/flex'
import { PanelInner } from '@components/panel-inner'
import { SkeletonBase } from '@components/skeleton-base'

export const DepositPageLoader = () => {
  return (
    <PanelInner>
      <Flex>
        <SkeletonBase height="xl" length="s" />
        <SkeletonBase height="xl" length="s" />
        <SkeletonBase height="xl" length="s" style={{ marginLeft: 'auto' }} />
      </Flex>
    </PanelInner>
  )
}

import { SelectOptionType, config } from '@shared/config'
import { devtools } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'
import { depositRepository } from '../domain'

type PaymentStore = {
  currencies: SelectOptionType<string>[]
  networks: SelectOptionType<string>[]
  isLoading: boolean
  setData: () => Promise<void>
}

export const paymentStore = createWithEqualityFn<PaymentStore>()(
  devtools(
    (set) => ({
      isLoading: false,
      currencies: [],
      networks: [],
      setData: async () => {
        set((state) => ({ ...state, isLoading: true }), false, 'loadCurrencies')
        try {
          const paymentMethodsInfo = await depositRepository.getMethodList({})
          const networks = paymentMethodsInfo.reduce((acc, method) => {
            const isDuplicate = acc.find((item) => item.value === method.method)
            if (!isDuplicate) {
              acc.push({
                label: method.methodDisplayName,
                value: method.method
              })
            }
            return acc
          }, [] as SelectOptionType<string>[])
          const currencyList = paymentMethodsInfo.map(
            (method) => method.currency
          )
          const uniqueCurrencies = Array.from(new Set(currencyList))
          const currencies = uniqueCurrencies.map((currency) => ({
            label: currency,
            value: currency
          }))
          set(
            (state) => ({
              ...state,
              isLoading: false,
              currencies: currencies,
              networks: networks
            }),
            false,
            'loadCurrenciesSuccess'
          )
        } catch {
          set(
            (state) => ({ ...state, isLoading: false }),
            false,
            'loadCurrenciesFailed'
          )
        }
      }
    }),
    {
      name: 'payment',
      enabled: config.isDevAppMode()
    }
  ),
  shallow
)

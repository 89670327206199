import cn from 'classnames'

import styles from './form-title.module.css'

interface FormTitleProps extends React.ComponentPropsWithoutRef<'div'> {
  title: string
}

export const FormTitle = ({ className, title, ...rest }: FormTitleProps) => {
  return (
    <h1 className={cn(styles.container, className)} {...rest}>
      {title}
    </h1>
  )
}

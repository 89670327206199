import cn from 'classnames'
import React, { forwardRef } from 'react'

import { LoaderCircle } from '@components/loader-circle'

import { ButtonSize, ButtonType } from './button.config'
import styles from './button.module.css'

interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  styleType?: ButtonType
  styleSize?: ButtonSize
  verticalIndent?: 'empty' | 'xs' | 'sm' | 'md' | 'lg'
  horizontalIndent?: 'empty' | 'xs' | 'sm' | 'md' | 'lg'
  gap?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  fullWidth?: boolean
  isLoading?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      styleType = 'primary',
      styleSize = 'sm',
      gap = 'sm',
      verticalIndent = 'sm',
      horizontalIndent = 'sm',
      isLoading = false,
      fullWidth = false,
      ...rest
    },
    ref
  ) => {
    const itemCls = cn(styles.container, className, {
      [styles.primary]: styleType === 'primary',
      [styles.secondary]: styleType === 'secondary',
      [styles.danger]: styleType === 'danger',
      [styles.transparent]: styleType === 'transparent',
      [styles.success]: styleType === 'success',
      [styles.extraSmall]: styleSize === 'xs',
      [styles.small]: styleSize === 'sm',
      [styles.medium]: styleSize === 'nm',
      [styles.large]: styleSize === 'lg',
      [styles.verticalIndentEmpty]: verticalIndent === 'empty',
      [styles.verticalIndentXs]: verticalIndent === 'xs',
      [styles.verticalIndentSm]: verticalIndent === 'sm',
      [styles.verticalIndentMd]: verticalIndent === 'md',
      [styles.verticalIndentLg]: verticalIndent === 'lg',
      [styles.horizontalIndentEmpty]: horizontalIndent === 'empty',
      [styles.horizontalIndentXs]: horizontalIndent === 'xs',
      [styles.horizontalIndentSm]: horizontalIndent === 'sm',
      [styles.horizontalIndentMd]: horizontalIndent === 'md',
      [styles.horizontalIndentLg]: horizontalIndent === 'lg',
      [styles.gapXs]: gap === 'xs',
      [styles.gapSm]: gap === 'sm',
      [styles.gapMd]: gap === 'md',
      [styles.gapLg]: gap === 'lg',
      [styles.gapXl]: gap === 'xl',
      [styles.fullWidth]: fullWidth === true
    })

    return (
      <button
        className={itemCls}
        ref={ref}
        {...rest}
        disabled={isLoading || rest.disabled}
      >
        {isLoading ? <LoaderCircle size="xs" /> : children}
      </button>
    )
  }
)

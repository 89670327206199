import { z } from 'zod'

export const ServiceSchema = z.object({
  id: z.string(),
  serviceName: z.string(),
  serviceCode: z.string(),
  availableCount: z.number(),
  serviceOldCode: z.string().nullable(),
  isFavorite: z.boolean(),
  cost: z.number().nullable()
  // bringTime: z.number(),
})

export const ServiceListSchema = z.array(ServiceSchema)

export const ServiceListRequestDTOSchema = z.object({
  favoriteIds: z.array(z.string()),
  countryCode: z.string().optional(),
  bringTime: z.string().optional(), // '20m' | '4h'
  operatorId: z.number().optional()
})

export enum OperatingSystemEnum {
  iOS = 'iOS',
  MacOS = 'Mac OS',
  Windows = 'Windows',
  Android = 'Android',
  Linux = 'Linux',
}

export const APP_WORK_MODE = {
  DEV: 'development',
  PROD: 'production',
} as const

export type WorkModeKeys = keyof typeof APP_WORK_MODE
export type WorkModeType = typeof APP_WORK_MODE[WorkModeKeys]

export const isValidMode = (mode: string) => {
  const isValid = Object.values(APP_WORK_MODE).includes(mode as WorkModeType)
  return isValid
}
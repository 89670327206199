import { PopupInner } from '@shared/layouts/popup-inner'
import { useMount } from './popup.lib'

interface PopupProps extends React.ComponentPropsWithoutRef<'div'> {
  withClose?: boolean
  withCenter?: boolean
  onClose: () => void
  isOpened: boolean
}

export const Popup = ({
  children,
  withCenter = false,
  withClose = true,
  onClose,
  isOpened,
  ...rest
}: PopupProps) => {
  const { mounted } = useMount({ opened: isOpened })
  if (!mounted) {
    return null
  }

  return (
    <PopupInner
      onClose={onClose}
      withClose={withClose}
      withCenter={withCenter}
      opened={isOpened}
      {...rest}
    >
      {children}
    </PopupInner>
  )
}

import { Popup } from '@shared/layouts/popup'
import { useRouteError } from 'react-router'

import { ErrorCore } from '@components/error-core'
import { useEffect } from 'react'

import * as Sentry from '@sentry/react'

export const DepositSuccessPageError = () => {
  const error = useRouteError()

  useEffect(() => {
    if (error) {
      Sentry.captureException(error)
    }
  }, [error])

  return (
    <Popup withClose={false} isOpened={true} onClose={() => {}}>
      <ErrorCore />
    </Popup>
  )
}

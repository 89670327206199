import { serverRepository } from '../domain'

const TELEGRAM_NEWS_QUERY_KEY = 'telegram_news'

export const telegramNewsListQuery = () => ({
  queryKey: [TELEGRAM_NEWS_QUERY_KEY],
  queryFn: () => serverRepository.getNewsList(),
  staleTime: 60000,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  retry: false
})

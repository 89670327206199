import cn from 'classnames'
import React from 'react'

import styles from './panel-inner.module.css'

interface PanelInnerProps extends React.ComponentPropsWithoutRef<'div'> {
  isNarrow?: boolean
  fullHeight?: boolean
}

export const PanelInner = ({
  children,
  className,
  isNarrow = false,
  fullHeight = false,
  ...rest
}: PanelInnerProps) => {
  return (
    <div
      className={cn(styles.container, className, {
        [styles.narrow]: isNarrow === true,
        [styles.fullHeight]: fullHeight === true
      })}
      {...rest}
    >
      {children}
    </div>
  )
}

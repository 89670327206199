import { backendInstance, PaginationResponse } from './base'

const BASE_URL = '/history'
const NUMBER_URL = 'number'

export enum SORT_FIELD {
  CREATION_DATE = 'creationDate',
  COUNTRY_CODE = 'countryCode',
  SERIVCE_CODE = 'serviceCode'
}

export type WithdrawHistoryItemType = {
  id: string
  paymentSystem: string
  value: number
  creationDate: string
}

export type WithdrawHistoryResponse = PaginationResponse<
  WithdrawHistoryItemType[]
>

export type DepositHistoryItemType = {
  id: string
  creationDate: Date
  amount: number
  inputCurrencyCode: string | null
  amountInputCurrency: number
  targetCurrencyCode: string | null
  paySystemName: string
}

export type DepositHistoryPayload = {
  page: number
  count: number
  sortField?: string
  sortType?: string
  startDt?: string
  endDt?: string
}
export type DepositHistoryResponse = PaginationResponse<
  DepositHistoryItemType[]
>

export type NumberHistoryItemType = {
  id: string
  creationDate: string
  countryCode: string
  serviceName: string
  countryIcon: string | null
  operatorName: string
  serviceIcon: string | null
  serviceCode: string
  telNumber: string
  telPrefix: string
  cost: number
  smsCode?: string
  isProlongation: boolean
  lastStatus: string
  lastActionDate: string
  rentStopDate: string
}

export type NumbersHistoryResponse = PaginationResponse<NumberHistoryItemType[]>

export const fetchHistoryDeposit = async (
  payload: DepositHistoryPayload
): Promise<DepositHistoryResponse> => {
  const response = await backendInstance.get(`${BASE_URL}/refill`, {
    params: {
      page: payload.page + 1 || 1,
      count: payload.count,
      ...(payload.sortField && { sortField: payload.sortField }),
      ...(payload.sortType && { sortType: payload.sortType }),
      ...(payload.startDt && { startDt: payload.startDt }),
      ...(payload.endDt && { endDt: payload.endDt })
    }
  })
  return response.data
}

export type WithdrawHistoryPayload = {
  page: number
  count: number
  sortField?: string
  sortType?: string
  startDt?: string
  endDt?: string
}

export const fetchHistoryWithdraw = async (
  payload: WithdrawHistoryPayload
): Promise<WithdrawHistoryResponse> => {
  const response = await backendInstance.get(`${BASE_URL}/withdraw`, {
    params: {
      page: payload.page + 1 || 1,
      count: payload.count,
      ...(payload.sortField && { sortField: payload.sortField }),
      ...(payload.sortType && { sortType: payload.sortType }),
      ...(payload.startDt && { startDt: payload.startDt }),
      ...(payload.endDt && { endDt: payload.endDt })
    }
  })
  return response.data
}

export type NumberHistoryPayload = {
  page: number
  count: number
  sortField?: string
  sortType?: string
  status?: string
  phone?: string
  onlyArchive?: boolean
  startDt?: string
  endDt?: string
}

export const fetchHistoryNumber = async (
  payload: NumberHistoryPayload
): Promise<NumbersHistoryResponse> => {
  const response = await backendInstance.get(`${NUMBER_URL}/history`, {
    params: {
      page: payload.page + 1 || 1,
      count: payload.count,
      ...(payload.sortField && { sortField: payload.sortField }),
      ...(payload.sortType && { sortType: payload.sortType }),
      ...(payload.status && { status: payload.status }),
      ...(payload.phone && { phone: payload.phone }),
      ...(payload.startDt && { startDt: payload.startDt }),
      ...(payload.endDt && { endDt: payload.endDt }),
      ...(payload.onlyArchive !== undefined && {
        onlyArchive: payload.onlyArchive
      })
    }
  })
  return response.data
}

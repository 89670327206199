import { Flex } from '@components/flex'
import { PanelInner } from '@components/panel-inner'
import { SkeletonBase } from '@components/skeleton-base'

export const NotFoundPageLoader = () => {
  return (
    <PanelInner fullHeight>
      <Flex
        direction="column"
        justify="center"
        align="center"
        style={{ height: '100%' }}
        gap="xl"
      >
        <SkeletonBase style={{ height: '10rem' }} length="s" />
        <SkeletonBase height="xl" length="s" />
      </Flex>
    </PanelInner>
  )
}

import { Flex } from '@components/flex'
import { PanelInner } from '@components/panel-inner'
import { SkeletonBase } from '@components/skeleton-base'

export const DepositSuccessPageLoader = () => {
  return (
    <PanelInner fullHeight>
      <Flex fullScreen justify="center" align="center">
        <Flex
          direction="column"
          align="center"
          style={{ width: '100%' }}
          gap="xl"
        >
          <SkeletonBase height="xl" length="l" />
          <SkeletonBase height="xxl" length="l" />
          <SkeletonBase height="xl" length="l" />
        </Flex>
      </Flex>
    </PanelInner>
  )
}

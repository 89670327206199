import { WithdrawModal } from '@features/withdraw'
import { GET_ENUMS, useGetPopupState } from './lib'

const popups = {
  [GET_ENUMS.popup.withdraw]: WithdrawModal
}

export const GetParameterPopup = () => {
  const { mountedPopup, isOpened } = useGetPopupState()
  if (!mountedPopup) {
    return null
  }

  const Component = popups[mountedPopup.toString()]

  if (!Component) {
    return null
  }
  return <Component isOpened={isOpened} />
}

import { STORAGE_KEYS } from '@shared/config/constants'

type KeyType = keyof typeof STORAGE_KEYS

export class StorageAPI {
  static save<T>(key: KeyType, value: T | T[]): void {
    return localStorage.setItem(STORAGE_KEYS[key], JSON.stringify(value))
  }
  static get<T>(key: KeyType): T | T[] | null {
    const value = localStorage.getItem(STORAGE_KEYS[key])
    if (!value) return null
    return JSON.parse(value)
  }
  static add<T>(key: KeyType, value: T): void {
    const serializedItem = localStorage.getItem(STORAGE_KEYS[key])
    if (!serializedItem) {
      return localStorage.setItem(STORAGE_KEYS[key], JSON.stringify(value))
    }
    const deserializedItem = JSON.parse(serializedItem)
    if (typeof deserializedItem === 'string') {
      return localStorage.setItem(
        STORAGE_KEYS[key],
        JSON.stringify([value, deserializedItem])
      )
    }
    if (deserializedItem instanceof Array) {
      return localStorage.setItem(
        STORAGE_KEYS[key],
        JSON.stringify([...deserializedItem, value])
      )
    }
  }
  static delete(key: KeyType): void {
    return localStorage.removeItem(STORAGE_KEYS[key])
  }
  static remove<T>(key: KeyType, value: T): void {
    const serializedItem = localStorage.getItem(STORAGE_KEYS[key])
    if (!serializedItem) return

    const deserializedItem = JSON.parse(serializedItem)
    if (typeof deserializedItem === 'string')
      return localStorage.removeItem(STORAGE_KEYS[key])
    if (deserializedItem instanceof Array) {
      const updatedDeserializedItem = deserializedItem.filter(
        (item) => item !== value
      )
      return localStorage.setItem(
        STORAGE_KEYS[key],
        JSON.stringify(updatedDeserializedItem)
      )
    }
  }
  static toggleArrayItem<T>(key: KeyType, value: T): void {
    const serializedItem = localStorage.getItem(STORAGE_KEYS[key])
    if (!serializedItem) {
      localStorage.setItem(STORAGE_KEYS[key], JSON.stringify(value))
    }
    const deserializedItem = JSON.parse(serializedItem as string)
    if (typeof deserializedItem === 'string') {
      if (deserializedItem === value) {
        return localStorage.removeItem(STORAGE_KEYS[key])
      }
      return localStorage.setItem(
        STORAGE_KEYS[key],
        JSON.stringify([value, deserializedItem])
      )
    }
    if (deserializedItem instanceof Array) {
      const foundIdx = deserializedItem.findIndex((item) => item === value)
      if (foundIdx < 0) {
        return localStorage.setItem(
          STORAGE_KEYS[key],
          JSON.stringify([value, ...deserializedItem])
        )
      }
      deserializedItem.splice(foundIdx, 1)
      return localStorage.setItem(
        STORAGE_KEYS[key],
        JSON.stringify(deserializedItem)
      )
    }
  }
}

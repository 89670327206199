import { useValidatedQueryParams } from '@shared/hooks/url'
import { useEffect, useMemo, useState } from 'react'
import { z } from 'zod'

export const GET_PARAMS = {
  popup: 'popup',
  notificationId: 'notification-id'
}

export const GET_ENUMS = {
  popup: {
    withdraw: 'withdraw'
  }
}

let timeout: any

export const useGetPopupState = () => {
  const { params } = useValidatedQueryParams({
    popupName: {
      type: z.string().optional(),
      name: GET_PARAMS.popup,
      defaultValue: ''
    }
  })

  const [mountedPopup, setMountedPopup] = useState<string | undefined>(
    params.popupName
  )

  useEffect(() => {
    if (params.popupName) {
      timeout && clearTimeout(timeout)
      setMountedPopup(params.popupName)
    } else {
      timeout = setTimeout(() => {
        setMountedPopup(undefined)
      }, 300)
    }
  }, [params.popupName])

  useEffect(() => {
    return () => {
      timeout && clearTimeout(timeout)
    }
  }, [])

  const isOpened = useMemo(() => Boolean(params.popupName), [params.popupName])

  return {
    mountedPopup,
    isOpened
  }
}

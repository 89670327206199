export interface ServerError {
  code: string
  message: string
  method: string
  path: string
  statusCode: number
  timestamp: string
}

export const isServerError = (error: unknown): error is ServerError => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'statusCode' in error &&
    'message' in error &&
    'code' in error &&
    'path' in error
  )
}

import { Flex } from '@components/flex'
import { PanelInner } from '@components/panel-inner'
import { SkeletonBase } from '@components/skeleton-base'
import { SkeletonTable } from '@components/table-infinite'

export const HistoryNumbersPageLoader = () => {
  return (
    <PanelInner fullHeight>
      <Flex direction="column" gap="xl">
        <SkeletonBase height="l" length="xxl" />
        <SkeletonTable columnCount={7} />
      </Flex>
    </PanelInner>
  )
}

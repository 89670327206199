import { Flex } from '@components/flex'
import { SkeletonBase } from '@components/skeleton-base'
import { FormWrapper } from '@shared/layouts/form-wrapper'

export const SigninPageLoader = () => {
  return (
    <Flex fullScreen align="center" justify="center" direction="column">
      <SkeletonBase height="l" length="s" />
      <FormWrapper style={{ width: 'min(400px, 90vw)' }}>
        <SkeletonBase height="xl" />
        <SkeletonBase height="xl" />
        <SkeletonBase height="xl" length="l" />
        <SkeletonBase height="xl" length="m" />
      </FormWrapper>
    </Flex>
  )
}

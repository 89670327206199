import { userApi } from '@shared/api/server'
import { SelectOptionType } from '@shared/config/type'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { Button } from '@components/button'
import { FormTitle } from '@components/form-title'
import { Input, InputSizeEnum, InputStyleTypeEnum } from '@components/input'
import { Select } from '@components/select'

import { useUserProfile } from '@entities/profile'
import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'
import styles from './withdraw-form.module.css'

interface WithdrawFormProps extends React.ComponentPropsWithoutRef<'div'> {}

export const WithdrawForm = ({ className, ...rest }: WithdrawFormProps) => {
  const { data } = useUserProfile()
  const balance = data?.balance ?? 0
  const { t: commonT } = useTranslation('common')
  const { t } = useTranslation('deposit')
  const options = [
    {
      label: 'Банковская карта',
      value: 'var1'
    },
    {
      label: 'Счет Qiwi',
      value: 'var2'
    },
    {
      label: 'Paypal',
      value: 'var3'
    }
  ]

  const validationSchema = z.object({
    withdrawType: z.string({
      required_error: commonT('errors.required') ?? ''
    }),
    requisite: z.string({
      required_error: commonT('errors.required') ?? ''
    }),
    amount: z
      .number({
        required_error: commonT('errors.required') ?? ''
      })
      .positive()
      .max(balance ?? 0)
  })

  const {
    register,
    handleSubmit,
    formState: { isValid, errors, isSubmitting, isDirty },
    control
  } = useForm<userApi.WithdrawPayload>({
    defaultValues: {
      withdrawType: '',
      requisite: '',
      amount: 0
    },
    resolver: zodResolver(validationSchema)
  })

  const onSubmit: SubmitHandler<userApi.WithdrawPayload> = (
    values: userApi.WithdrawPayload
  ) => {
    alert(values)
  }

  return (
    <div className={cn(styles.container, className)} {...rest}>
      <FormTitle className={styles.title} title={t('title.withdraw')} />
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <Controller
          name="withdrawType"
          control={control}
          render={({ field }) => (
            <Select
              styleSize="md"
              borderType="primary"
              options={options}
              fullHeight={true}
              isSearchable={false}
              placeholder={t('controls.withdraw-type')}
              className={styles.input}
              // getOptionLabel={(option) => option.label}
              value={options.find((c) => c.value === field.value)}
              // @ts-ignore
              onChange={(val: SelectOptionType<string>) =>
                field.onChange(val.value)
              }
              error={errors?.withdrawType?.message}
            />
          )}
        />
        <Input
          type="text"
          autoComplete={'off'}
          error={errors?.requisite?.message}
          withErrorText={false}
          placeholder={t('controls.requesite') ?? ''}
          styleType={InputStyleTypeEnum.SECONDARY}
          styleSize={InputSizeEnum.MEDIUM}
          className={styles.input}
          {...register('requisite')}
        />
        <Input
          type="number"
          min={0}
          step={1}
          autoComplete={'off'}
          error={errors?.amount?.message}
          withErrorText={false}
          placeholder={t('controls.sum') ?? ''}
          styleType={InputStyleTypeEnum.SECONDARY}
          styleSize={InputSizeEnum.MEDIUM}
          className={styles.input}
          {...register('amount', {
            valueAsNumber: true
          })}
        />
        <Button
          styleType={'primary'}
          styleSize={'nm'}
          type="submit"
          className={styles.btn}
          isLoading={isSubmitting}
          disabled={isSubmitting || !isDirty || !isValid}
        >
          {t('buttons.withdraw')}
        </Button>
      </form>
      <span className={styles.info}>
        {/* TODO: Информации о комиссии. Комиссия может взматься в связи с чем-то там */}
      </span>
    </div>
  )
}

import { Flex } from '@components/flex'
import { PanelInner } from '@components/panel-inner'
import { SkeletonBase } from '@components/skeleton-base'

export const TermsPageLoader = () => {
  return (
    <PanelInner fullHeight>
      <Flex direction="column" gap="md">
        <SkeletonBase
          height="xl"
          length="m"
          style={{ marginBottom: 'var(--gap-m)' }}
        />
        <SkeletonBase height="l" />
        <SkeletonBase height="l" />
        <SkeletonBase height="l" />
        <SkeletonBase height="l" />
        <SkeletonBase height="l" />
        <SkeletonBase height="l" />
        <SkeletonBase height="l" />
      </Flex>
    </PanelInner>
  )
}

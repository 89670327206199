import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { Button } from '@components/button'

import { ReactComponent as Logo } from './assets/img/logo.svg'

import { Flex } from '@components/flex'
import { Text } from '@components/text'
import { Title } from '@components/title'
import { PAGES } from '@shared/config'
import { useNavigate } from 'react-router'
import styles from './error-core.module.css'

type ErrorCoreProps = {
  className?: string
}

export const ErrorCore = ({ className }: ErrorCoreProps) => {
  const { t } = useTranslation('error')
  const navigate = useNavigate()
  const handleHomeClick = () => {
    navigate(PAGES.ROOT_PAGE, {
      replace: true
    })
  }

  return (
    <Flex
      direction="column"
      align="center"
      verticalIndent="lg"
      horizontalIndent="md"
      className={cn(styles.container, className)}
    >
      <Logo className={styles.icon} />
      <Title>{t('something-wrong')}</Title>
      <Text size="md">{t('try-later')}</Text>
      <Button className={styles.btn} styleSize="sm" onClick={handleHomeClick}>
        {t('back-home')}
      </Button>
    </Flex>
  )
}

import { ModelError } from '@shared/errors/model.error'
import { DepositServiceSchema } from '../deposit.schema'

export class DepositServiceModel {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly url: string
  ) {}

  static fromAPI(data: unknown): DepositServiceModel {
    const result = DepositServiceSchema.safeParse(data)
    if (!result.success) {
      throw new ModelError('INVALID_DATA_FORMAT', result.error.errors)
    }
    return new DepositServiceModel(
      result.data.id,
      result.data.name,
      result.data.paymentServiceURL
    )
  }
}

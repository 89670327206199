import { sessionApi, softsApi, userApi } from '@shared/api/server'
import { ApiError } from '@shared/errors/api.error'
import { ModelError } from '@shared/errors/model.error'
import { RepositoryError } from '@shared/errors/repository.error'
import { ChangePasswordDTO } from '../profile.types'
import { ProfileModel } from './profile.model'
import { SoftModel } from './soft.model'

export class ProfileRepository {
  async getProfile(): Promise<ProfileModel> {
    try {
      const profileResponse = await userApi.fetchUserProfile()
      const data = ProfileModel.fromAPI(profileResponse)

      return data
    } catch (error) {
      if (error instanceof ModelError) {
        console.log(
          'catch: ',
          error.errors
            ?.map((error) => `${error.path} - ${error.message}`)
            .join(',')
        )
        throw new RepositoryError(error.code, error.message)
      } else if (error instanceof ApiError) {
        throw new RepositoryError(error.code, error.message)
      } else {
        throw error
      }
    }
  }

  async sendVerificationCode(): Promise<void> {
    try {
      return await userApi.sendVerificationCodeCall()
    } catch (error) {
      if (error instanceof ModelError) {
        console.log(
          'catch: ',
          error.errors
            ?.map((error) => `${error.path} - ${error.message}`)
            .join(',')
        )
        throw new RepositoryError(error.code, error.message)
      } else if (error instanceof ApiError) {
        throw new RepositoryError(error.code, error.message)
      } else {
        throw error
      }
    }
  }

  async checkVerificationCode({ code }: { code: string }): Promise<string> {
    try {
      return await userApi.checkVerificationCodeCall({ code })
    } catch (error) {
      if (error instanceof ModelError) {
        console.log(
          'catch: ',
          error.errors
            ?.map((error) => `${error.path} - ${error.message}`)
            .join(',')
        )
        throw new RepositoryError(error.code, error.message)
      } else if (error instanceof ApiError) {
        throw new RepositoryError(error.code, error.message)
      } else {
        throw error
      }
    }
  }

  async changePassword(dto: ChangePasswordDTO): Promise<void> {
    try {
      return await userApi.changePasswordCall(dto)
    } catch (error) {
      if (error instanceof ModelError) {
        console.log(
          'catch: ',
          error.errors
            ?.map((error) => `${error.path} - ${error.message}`)
            .join(',')
        )
        throw new RepositoryError(error.code, error.message)
      } else if (error instanceof ApiError) {
        throw new RepositoryError(error.code, error.message)
      } else {
        throw error
      }
    }
  }

  async toggleTgNotification(newValue: boolean): Promise<void> {
    try {
      return await userApi.changeTgNotificationValue(newValue)
    } catch (error) {
      if (error instanceof ModelError) {
        console.log(
          'catch: ',
          error.errors
            ?.map((error) => `${error.path} - ${error.message}`)
            .join(',')
        )
        throw new RepositoryError(error.code, error.message)
      } else if (error instanceof ApiError) {
        throw new RepositoryError(error.code, error.message)
      } else {
        throw error
      }
    }
  }

  async unlinkTelegram(): Promise<void> {
    try {
      return await userApi.unlinkTelegram()
    } catch (error) {
      if (error instanceof ModelError) {
        console.log(
          'catch: ',
          error.errors
            ?.map((error) => `${error.path} - ${error.message}`)
            .join(',')
        )
        throw new RepositoryError(error.code, error.message)
      } else if (error instanceof ApiError) {
        throw new RepositoryError(error.code, error.message)
      } else {
        throw error
      }
    }
  }

  async resetPassword({ username }: { username: string }): Promise<void> {
    try {
      await sessionApi.resetPasswordCall({ username })
      return
    } catch (error) {
      if (error instanceof ModelError) {
        console.log(
          'catch: ',
          error.errors
            ?.map((error) => `${error.path} - ${error.message}`)
            .join(',')
        )
        throw new RepositoryError(error.code, error.message)
      } else if (error instanceof ApiError) {
        throw new RepositoryError(error.code, error.message)
      } else {
        throw error
      }
    }
  }

  async getSoftList(): Promise<SoftModel[]> {
    try {
      const softListResponse = await softsApi.fetchSoftList()
      const data = softListResponse.map((soft) => SoftModel.fromAPI(soft))

      return data
    } catch (error) {
      if (error instanceof ModelError) {
        console.log(
          'catch: ',
          error.errors
            ?.map((error) => `${error.path} - ${error.message}`)
            .join(',')
        )
        throw new RepositoryError(error.code, error.message)
      } else if (error instanceof ApiError) {
        throw new RepositoryError(error.code, error.message)
      } else {
        throw error
      }
    }
  }

  async transferSoftBalance({
    softId,
    amount
  }: {
    softId: string
    amount: number
  }): Promise<void> {
    try {
      return await softsApi.softBalanceTransfer({ softId, amount })
    } catch (error) {
      if (error instanceof ModelError) {
        console.log(
          'catch: ',
          error.errors
            ?.map((error) => `${error.path} - ${error.message}`)
            .join(',')
        )
        throw new RepositoryError(error.code, error.message)
      } else if (error instanceof ApiError) {
        throw new RepositoryError(error.code, error.message)
      } else {
        throw error
      }
    }
  }
}

import React from 'react'

import { SkeletonBase } from '@components/skeleton-base'
import styles from './table-skeleton.module.css'

interface SkeletonTableProps extends React.ComponentPropsWithoutRef<'div'> {
  columnCount: number
}

export const SkeletonTable = ({ columnCount, ...rest }: SkeletonTableProps) => {
  const columns = React.useMemo(
    () =>
      [...Array(columnCount)].map((_, i) => (
        <SkeletonBase height="l" className={styles.item} key={i} />
      )),
    [columnCount]
  )
  return (
    <div className={styles.container} {...rest}>
      <div className={styles.header}>{columns}</div>
      <div className={styles.row}>{columns}</div>
      <div className={styles.row}>{columns}</div>
      <div className={styles.row}>{columns}</div>
      <div className={styles.row}>{columns}</div>
      <div className={styles.row}>{columns}</div>
      <div className={styles.row}>{columns}</div>
      <div className={styles.row}>{columns}</div>
      <div className={styles.row}>{columns}</div>
      <div className={styles.row}>{columns}</div>
      <div className={styles.row}>{columns}</div>
    </div>
  )
}

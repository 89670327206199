import { useCallback } from 'react'
import { sessionStore } from '../store/session.store'

export const useCheckAuthAsync = () => {
  const refreshSession = sessionStore((state) => state.refreshSession)
  const isLoading = sessionStore((state) => state.isLoading)

  const handleRefreshSession = useCallback(async () => {
    refreshSession()
  }, [refreshSession])

  return {
    refreshSession: handleRefreshSession,
    isLoading
  }
}

import { sessionApi } from '@shared/api/server'
import { backendInstance } from '@shared/api/server/base'
import { PAGES } from '@shared/config'
import { isServerError } from '@shared/errors/server.error'
import { sessionStore } from '@shared/session'
import { AxiosError, InternalAxiosRequestConfig } from 'axios'
import decode from 'jwt-decode'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

export const initInterceptors = () => {
  const { t } = useTranslation('server-errors')
  const { setSession, removeSession } = sessionStore.getState()

  backendInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      const token = sessionStore.getState().token
      if (token) {
        config.headers!.Authorization = `Bearer ${token}`
      }
      return config
    },
    (error: AxiosError) => {
      Promise.reject(error)
    }
  )

  backendInstance.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      const url: string = error.response.config.url
      const originalConfig = error.config
      if (error.response) {
        switch (error.response.status) {
          case 401: {
            if (error.config && !error.config._isRetry) {
              originalConfig._isRetry = true
              try {
                const response = await sessionApi.refreshTokenCall()
                const { accessToken } = response
                if (!accessToken) {
                  return Promise.reject(error)
                }
                backendInstance.interceptors.request.use(
                  (config: InternalAxiosRequestConfig) => {
                    config.headers!.Authorization = `Bearer ${accessToken}`
                    return config
                  },
                  (error: AxiosError) => {
                    return Promise.reject(error)
                  }
                )
                const params: { email: string; sub: string } =
                  decode(accessToken)
                setSession({
                  token: accessToken,
                  username: params.email,
                  id: params.sub
                })
                return backendInstance(originalConfig)
              } catch (_error: any) {
                removeSession()
                return Promise.reject(_error)
              }
            }
            removeSession()
            return Promise.reject(error)
          }
          case 403:
            if (!url.endsWith('forbidden')) {
              location.href = `/${PAGES.FORBIDDEN_PAGE}`
            }
            break
          case 404:
            location.href = PAGES.NOT_FOUND_PAGE
            break
          case 500:
            // toast.error('Сервер вернул ошибку 500')
            break
          default:
            console.log('Other error')
            break
        }
      }
      const errorObject = error?.response?.data
      if (isServerError(errorObject)) {
        const translatedMessage = t(`${errorObject.code}`, {
          defaultValue: 'unknown'
        })
        if (translatedMessage !== 'unknown') {
          toast.error(translatedMessage)
        }
        return Promise.reject(translatedMessage)
      }
      return Promise.reject(error?.response?.data)
    }
  )
}

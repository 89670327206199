import { serviceApi } from '@shared/api/server'
import { ApiError } from '@shared/errors/api.error'
import { ModelError } from '@shared/errors/model.error'
import { RepositoryError } from '@shared/errors/repository.error'
import { ServiceListRequestDTO } from '../schema.types'
import { ServiceModel } from './service.model'

export class ServiceRepository {
  async getList(dto: ServiceListRequestDTO): Promise<ServiceModel[]> {
    try {
      const serviceResponse = await serviceApi.fetchServices(dto)
      const data = serviceResponse.map((service) =>
        ServiceModel.fromAPI(service)
      )

      return data
    } catch (error) {
      if (error instanceof ModelError) {
        console.log(
          'catch: ',
          error.errors
            ?.map((error) => `${error.path} - ${error.message}`)
            .join(',')
        )
        throw new RepositoryError(error.code, error.message)
      } else if (error instanceof ApiError) {
        throw new RepositoryError(error.code, error.message)
      } else {
        throw error
      }
    }
  }
}

import { ModelError } from '@shared/errors/model.error'
import { DepositMethodSchema } from '../deposit.schema'

export class DepositMethodModel {
  constructor(
    public readonly id: number,
    public readonly commissionPercent: number,
    public readonly currency: string,
    public readonly method: string,
    public readonly methodDisplayName: string,
    public readonly serviceId: number
  ) {}

  static fromAPI(data: unknown): DepositMethodModel {
    const result = DepositMethodSchema.safeParse(data)
    if (!result.success) {
      throw new ModelError('INVALID_DATA_FORMAT', result.error.errors)
    }
    return new DepositMethodModel(
      result.data.id,
      result.data.commissionPercent ?? 0,
      result.data.currency,
      result.data.paymentMethod,
      result.data.paymentMethodDisplayName,
      result.data.paymentServiceId
    )
  }
}

import { backendInstance } from './base'

const BASE_URL = '/user'

type SoftType = {
  balance: number
  creationDate: Date
  incomePercent: number
  softId: string
  softName: string
}

type SoftListResponse = SoftType[]

export const fetchSoftList = async (): Promise<SoftListResponse> => {
  const response = await backendInstance.get(`${BASE_URL}/softs`)
  return response.data
}

type TransferBalanceRequest = {
  softId: string
  amount: number
}
type TransferBalanceResponse = void

export const softBalanceTransfer = async (
  payload: TransferBalanceRequest
): Promise<TransferBalanceResponse> => {
  const response = await backendInstance.post(
    `${BASE_URL}/softs/topup`,
    payload
  )
  return response.data
}

import { AuthUser } from '@shared/api'
import {
  refreshTokenCall,
  signInCall,
  SigninPayload,
  signoutCall
} from '@shared/api/server/session'
import { config } from '@shared/config'
import decode from 'jwt-decode'
import { devtools } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

type SessionStore = {
  token: string | null
  username: string
  id: string
  isLoading: boolean
  setSession: (payload: AuthUser) => Promise<void>
  loadSession: (payload: SigninPayload) => Promise<void>
  refreshSession: () => Promise<void>
  removeSession: () => Promise<void>
}

export const sessionStore = createWithEqualityFn<SessionStore>()(
  devtools(
    (set) => ({
      isLoading: false,
      username: '',
      id: '',
      token: null,
      setSession: async (payload: AuthUser) => {
        set(
          (state) => ({
            ...state,
            token: payload.token,
            id: payload.id,
            username: payload.username
          }),
          false,
          'setSession'
        )
      },
      refreshSession: async () => {
        await set(
          (state) => ({ ...state, isLoading: true }),
          false,
          'refreshSession'
        )
        try {
          const session = await refreshTokenCall()
          const { accessToken: accessToken } = session
          const params: { email: string; sub: string } = decode(accessToken)
          await set(
            (state) => ({
              ...state,
              token: accessToken,
              username: params.email,
              id: params.sub,
              isLoading: false
            }),
            false,
            'refreshSessionSuccess'
          )
          return
        } catch (error) {
          await set(
            (state) => ({
              ...state,
              isLoading: false,
              token: null,
              username: '',
              id: ''
            }),
            false,
            'refreshSessionFailed'
          )
          // throw error
        }
      },
      loadSession: async (payload: SigninPayload) => {
        await set(
          (state) => ({ ...state, isLoading: true }),
          false,
          'loadSession'
        )
        try {
          const session = await signInCall(payload)
          const { accessToken: accessToken } = session
          const params: { email: string; sub: string } = decode(accessToken)
          await set(
            (state) => ({
              ...state,
              token: accessToken,
              username: params.email,
              id: params.sub,
              isLoading: false
            }),
            false,
            'loadSessionSuccess'
          )
          return
        } catch (error) {
          await set(
            (state) => ({
              ...state,
              isLoading: false,
              token: null,
              username: '',
              id: ''
            }),
            false,
            'loadSessionFailed'
          )
          throw error
        }
      },
      removeSession: async () => {
        try {
          await signoutCall()
        } finally {
          set(
            (state) => ({
              ...state,
              token: null,
              username: '',
              id: ''
            }),
            true,
            'removeSession'
          )
        }
      }
    }),
    {
      name: 'session',
      enabled: config.isDevAppMode()
    }
  ),
  shallow
)

import { z } from 'zod'

// #region HISTORY

export const DepositRecordSchema = z.object({
  id: z.string(),
  creationDate: z
    .string()
    .or(z.date())
    .transform((arg) => new Date(arg)),
  amount: z.number(),
  inputCurrencyCode: z.string().nullable(),
  amountInputCurrency: z.number().catch(0),
  targetCurrencyCode: z.string().nullable(),
  paySystemName: z.string().catch('unknown')
})
export const DepositHistoryListSchema = z.array(DepositRecordSchema)

export const DepositHistoryListRequestDTOSchema = z.object({
  count: z.number().min(0).default(50),
  sortField: z.string().optional(),
  sortType: z.string().optional(),
  startDt: z.string().optional(),
  endDt: z.string().optional()
})

// #endregion

// #region METHODS
export const DepositMethodSchema = z.object({
  id: z.number(),
  commissionPercent: z.number().nullable(),
  currency: z.string(),
  paymentMethod: z.string(),
  paymentMethodDisplayName: z.string(),
  paymentServiceId: z.number()
})
export const DepositMethodListSchema = z.array(DepositMethodSchema)
export const DepositMethodRequestDTOSchema = z.object({
  currency: z.string().optional(),
  paymentMethod: z.string().optional()
})
// #endregion

// #region SERVICES
export const DepositServiceSchema = z.object({
  id: z.number(),
  name: z.string(),
  paymentServiceURL: z.string()
})
export const DepositServiceListSchema = z.array(DepositServiceSchema)
export const DepositServiceRequestDTOSchema = z.object({
  currency: z.string().optional(),
  paymentMethod: z.string().optional()
})
// #endregion

// #region DEPOSIT REQUEST
export const DepositRequestDTORequestSchema = z.object({
  sourceNetwork: z.string(),
  sourceCurrency: z.string(),
  targetCurrency: z.string(),
  targetValue: z.number(),
  paymentSystem: z.string()
})
export const DepositRequestDTOResponseSchema = z.object({
  payHistoryId: z.string(),
  redirectUrl: z.string(),
  webhookUrl: z.string()
})
// #endregion

// #region DEPOSIT BY COUPON REQUEST

export const DepositByCouponRequestDTORequestSchema = z.object({
  code: z.string()
})
export const DepositByCouponRequestDTOResponseSchema = z.object({
  result: z.string()
})

// #endregion

// #region DEPOSIT BY GARANTEX COUPON REQUEST

export const DepositByGarantexCouponRequestDTORequestSchema = z.object({
  code: z.string()
})
export const DepositByGarantexCouponRequestDTOResponseSchema = z.object({
  result: z.string()
})

// #endregion

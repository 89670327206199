import { Flex } from '@components/flex'
import { SkeletonBase } from '@components/skeleton-base'

export const ProfilePageLoader = () => {
  return (
    <Flex direction="column" gap="xl">
      <Flex direction="column">
        <SkeletonBase height="xl" length="m" />
        <SkeletonBase height="l" length="m" />
        <SkeletonBase height="l" length="m" />
        <SkeletonBase height="l" length="m" />
      </Flex>

      <Flex direction="column">
        <SkeletonBase height="l" length="m" />
        <SkeletonBase height="xxl" length="m" />
        <SkeletonBase height="xxl" length="m" />
      </Flex>
    </Flex>
  )
}

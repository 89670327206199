import { useIsUserAuth } from '@shared/session'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'

import { URL_PARAMS } from '@shared/config'
import { useValidatedQueryParams } from '@shared/hooks/url'
import { z } from 'zod'
import { DepositStorageMethod } from '../deposit.types'
import {
  depositHistoryListQuery,
  depositMethodListQuery,
  depositServiceListQuery
} from './deposit.query'
import { paymentStore } from './deposit.store'

export const useLoadDepositsFilter = () => {
  const loadFilters = paymentStore((state) => state.setData)
  const isLoading = paymentStore((state) => state.isLoading)

  const handleLoadDepositFilters = useCallback(async () => {
    loadFilters()
  }, [loadFilters])

  return {
    loadFilters: handleLoadDepositFilters,
    isLoading
  }
}

export const useCurrenciesOptions = () =>
  paymentStore((state) => ({
    currencies: state.currencies,
    isLoading: state.isLoading
  }))
export const useNetworksOptions = () =>
  paymentStore((state) => ({
    networks: state.networks,
    isLoading: state.isLoading
  }))

export const useDepositServices = () => {
  const { params } = useValidatedQueryParams({
    currency: { type: z.string(), name: URL_PARAMS.PAYMENT_CURRENCY },
    paymentMethod: { type: z.string(), name: URL_PARAMS.PAYMENT_METHOD }
  })

  const isAuth = useIsUserAuth()
  return useQuery({
    ...depositServiceListQuery(
      { currency: params.currency, paymentMethod: params.paymentMethod },
      isAuth
    )
  })
}
export const useDepositMethods = (favoriteMethods: DepositStorageMethod[]) => {
  const { params } = useValidatedQueryParams({
    currency: { type: z.string(), name: URL_PARAMS.PAYMENT_CURRENCY },
    method: { type: z.string(), name: URL_PARAMS.PAYMENT_METHOD }
  })
  const isAuth = useIsUserAuth()
  return useQuery({
    ...depositMethodListQuery(
      { currency: params.currency, paymentMethod: params.method },
      favoriteMethods,
      isAuth
    )
  })
}

export const useHistoryDepositList = () => {
  const isAuth = useIsUserAuth()
  const { params } = useValidatedQueryParams({
    startDt: { type: z.string().optional(), name: URL_PARAMS.START_DATE },
    endDt: { type: z.string().optional(), name: URL_PARAMS.END_DATE },
    sortingField: { type: z.string().optional(), name: URL_PARAMS.SORT_BY },
    sortingType: { type: z.string().optional(), name: URL_PARAMS.SORT }
  })

  return useInfiniteQuery({
    ...depositHistoryListQuery(
      {
        startDt: params.startDt,
        endDt: params.endDt,
        sortingField: params.sortingField,
        sortingType: params.sortingType
      },
      isAuth
    ),
    select: (data) => data?.pages.map((item) => item.data).flat()
  })
}

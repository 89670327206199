import React from 'react'

interface DynamicImgProps extends React.ComponentPropsWithoutRef<'img'> {
  thumbPath: string
  imgPath: string
}

export const DynamicImg = React.memo(
  ({ className, thumbPath, imgPath, ...rest }: DynamicImgProps) => {
    const [imgSrc, setImgSrc] = React.useState<string>(thumbPath || imgPath)

    const onLoad = React.useCallback(() => {
      setImgSrc(imgPath)
    }, [imgPath])

    const onError = React.useCallback(() => {
      setImgSrc(thumbPath)
    }, [thumbPath])

    React.useEffect(() => {
      const img = new Image()
      img.src = imgPath as string
      img.addEventListener('load', onLoad)
      img.addEventListener('error', onError)

      return () => {
        img.removeEventListener('load', onLoad)
        img.removeEventListener('error', onError)
      }
    }, [imgPath, onLoad, onError])

    return (
      <img
        className={className}
        src={imgSrc}
        alt="Лого"
        style={{ height: '100%' }}
        {...rest}
      />
    )
  }
)

import cn from 'classnames'
import { ReactComponent as Logo } from './assets/img/logo.svg'
import styles from './withdraw-icon.module.css'

interface WithdrawIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: 'xs' | 'sm' | 'md' | 'lg'
  type?: 'primary' | 'inactive' | 'inverse' | 'accent' | 'danger' | 'current'
  title?: string | null
}

export const WithdrawIcon = ({
  className,
  size = 'md',
  type = 'current',
  title,
  ...rest
}: WithdrawIconProps) => {
  const Icon = (
    <Logo
      className={cn(styles.container, className, {
        [styles.xs]: size === 'xs',
        [styles.sm]: size === 'sm',
        [styles.md]: size === 'md',
        [styles.lg]: size === 'lg',
        [styles.current]: type === 'current',
        [styles.primary]: type === 'primary',
        [styles.inactive]: type === 'inactive',
        [styles.inverse]: type === 'inverse',
        [styles.accent]: type === 'accent',
        [styles.danger]: type === 'danger'
      })}
      {...rest}
    />
  )
  return title ? <span title={title}>{Icon}</span> : Icon
}

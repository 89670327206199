import { Flex } from '@components/flex'
import { PanelInner } from '@components/panel-inner'
import { SkeletonBase } from '@components/skeleton-base'
import styles from './contacts-page.module.css'

export const ContactsPageLoader = () => {
  return (
    <PanelInner className={styles.container}>
      <Flex direction="column" gap="md">
        <SkeletonBase length="s" height="l" />
        <SkeletonBase style={{ margin: 'var(--gap-m) 0' }} />
        <SkeletonBase length="s" />
        <SkeletonBase length="s" />
        <SkeletonBase length="s" />
      </Flex>
    </PanelInner>
  )
}

import { backendInstance } from './base'
const BASE_URL = '/telegram'

export const fetchTelegramNews = async ({
  count
}: {
  count: number
}): Promise<string[]> => {
  const response = await backendInstance.get(`${BASE_URL}/news/list`, {
    params: {
      count
    },
    headers: {
      tgrequest: true
    }
  })
  return response.data
}

import { profileRepository } from '../domain'

const PROFILE_QUERY_KEY = 'user_profile'
const SOFT_QUERY_KEY = 'user_soft'

export const userProfileQuery = ({ isUserAuth }: { isUserAuth: boolean }) => ({
  queryKey: [PROFILE_QUERY_KEY],
  queryFn: () => profileRepository.getProfile(),
  staleTime: 60000,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  enabled: isUserAuth,
  retry: false
})

export const userSoftListQuery = ({ isUserAuth }: { isUserAuth: boolean }) => ({
  queryKey: [SOFT_QUERY_KEY],
  queryFn: () => profileRepository.getSoftList(),
  staleTime: 60000,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  enabled: isUserAuth,
  retry: false
})

import { PAGES } from '@shared/config/constants'
import { useIsUserAuth } from '@shared/session'
import { Navigate, Outlet, useLocation } from 'react-router'

export const RequireAuth = () => {
  const isAuth = useIsUserAuth()
  const location = useLocation()

  if (!isAuth) {
    return <Navigate to={PAGES.AUTH_PAGE} state={{ from: location }} replace />
  }

  return <Outlet />
}
